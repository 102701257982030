import { BEFORE_CONTENT, GET_CONTENT, GET_HEADER_CONTENT, GET_POPULAR_CATEGORIES_CONTENT, GET_FEATURE_ADS_CONTENT, GET_LAST_ADS_CONTENT, GET_FOOTER_CONTENT, GET_PROFILE_CONTENT, BEFORE_HEADER_CONTENT, BEFORE_FOOTER_CONTENT, GET_AD_CONTENT, BEFORE_AD_CONTENT, BEFORE_AD_COMPONENT_CONTENT, GET_AD_COMPONENT_CONTENT, GET_PASSWORD_MANAGEMENT_CONTENT, BEFORE_PASSWORD_MANAGEMENT, BEFORE_TRUST_CERTIFICATION, GET_TRUST_CERTIFICATION_CONTENT, BEFORE_BANNER_CONTENT, GET_BANNER_CONTENT, GET_WELCOME_PACK_CONTENT, BEFORE_WELCOME_PACK_CONTENT, GET_SIGN_IN_CONTENT, GET_FAQS_CONTENT, GET_CONTROL_PANEL_CONTENT, GET_LANDING_PAGE_CONTENT, GET_SETTINGS_SIDEBAR_CONTENT, GET_FAV_ADS_CONTENT, GET_PHONE_NO_CONTENT, GET_POST_AD_CONTENT, GET_PUBLICATION_ADS_CONTENT, GET_AD_PLANNER_CONTENT, GET_BUY_TICKETS_CONTENT, GET_BILLING_CONTENT, GET_RENEW_ADS_CONTENT, GET_RELOCATE_ADS_CONTENT, GET_CERTIFICATE_ADS_CONTENT, GET_CERTIFICATION_ADS_CONTENT, GET_PROMOS_CONTENT, GET_CONTACT_SUPPORT_CONTENT, GET_AD_DETAIL_CONTENT, GET_GENERATED_BILL_CONTENT, GET_REUBICATE_ADS_CONTENT, GET_PUBLICATE_ADS_CONTENT, GET_REPLAN_AD_CONTENT, GET_ALL_CATEGORIES_CONTENT, GET_PREVIEW_AD_CONTENT, GET_FILTERED_ADS_CONTENT, GET_LOI_CONTENT, GET_BONUS_PAYMENT_CONTENT, GET_PAYMENT_CONTENT, GET_NOTICE_CONTENT } from '../../redux/types'

const initialState = {
    pageContent: {},
    pageContentAuth: false,
    headerPageContentAuth: false,
    popularCategoriesPageContentAuth: false,
    featureAdsPageContentAuth: false,
    lastAdsPageContentAuth: false,
    footerPageContentAuth: false,
    profilePageContentAuth: false,
    adPageContentAuth: false,
    adComponentPageContentAuth: false,
    passwordManagementContentAuth: false,
    trustCertificationContentAuth: false,
    promotionalBannerContentAuth: false,
    welcomePackContentAuth: false,
    signInContentAuth: false,
    faqsContentAuth: false,
    controlPanelContentAuth: false,
    landingPageContentAuth: false,
    settingsSidebarAuth: false,
    favAdsAuth: false,
    phoneContentAuth: false,
    postAdPageContentAuth: false,
    publicationAdsContentAuth: false,
    adPlannerContentAuth: false,
    buyTicketsPageContentAuth: false,
    billingContentAuth: false,
    renewAdsContentAuth: false,
    relocateAdsContentAuth: false,
    certificateAdsContentAuth: false,
    certificationAdsContentAuth: false,
    promosContentAuth: false,
    contactSupportContentAuth: false,
    adDetailContentAuth: false,
    generatedBillContentAuth: false,
    reubicateAdsContentAuth: false,
    publicateAdsContentAuth: false,
    replanAdContentAuth: false,
    allCategoriesContentAuth: false,
    previewAdContentAuth: false,
    filteredAdContentAuth: false,
    loiContentAuth: false,
    bonusPaymentContentAuth: false,
    paymentContentAuth: false,
    noticeContentAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTENT:
            return {
                ...state,
                pageContent: action.payload,
                pageContentAuth: true
            }
        case GET_HEADER_CONTENT:
            return {
                ...state,
                headerPageContent: action.payload,
                headerPageContentAuth: true
            }
        case GET_POPULAR_CATEGORIES_CONTENT:
            return {
                ...state,
                popularCategoriesPageContent: action.payload,
                popularCategoriesPageContentAuth: true
            }
        case GET_WELCOME_PACK_CONTENT:
            return {
                ...state,
                welcomePackContent: action.payload,
                welcomePackContentAuth: true
            }
        case GET_FEATURE_ADS_CONTENT:
            return {
                ...state,
                featureAdsPageContent: action.payload,
                featureAdsPageContentAuth: true
            }
        case GET_BANNER_CONTENT:
            return {
                ...state,
                promotionalBannerContent: action.payload,
                promotionalBannerContentAuth: true
            }
        case GET_LAST_ADS_CONTENT:
            return {
                ...state,
                lastAdsPageContent: action.payload,
                lastAdsPageContentAuth: true
            }
        case GET_FOOTER_CONTENT:
            return {
                ...state,
                footerPageContent: action.payload,
                footerPageContentAuth: true
            }
        case GET_PROFILE_CONTENT:
            return {
                ...state,
                profilePageContent: action.payload,
                profilePageContentAuth: true
            }
        case GET_AD_CONTENT:
            return {
                ...state,
                adPageContent: action.payload,
                adPageContentAuth: true
            }
        case GET_AD_COMPONENT_CONTENT:
            return {
                ...state,
                adComponentPageContent: action.payload,
                adComponentPageContentAuth: true
            }
        case GET_PASSWORD_MANAGEMENT_CONTENT:
            return {
                ...state,
                passwordManagementPageContent: action.payload,
                passwordManagementContentAuth: true
            }
        case GET_TRUST_CERTIFICATION_CONTENT:
            return {
                ...state,
                trustCertificationPageContent: action.payload,
                trustCertificationContentAuth: true
            }
        case GET_SIGN_IN_CONTENT:
            return {
                ...state,
                signInContent: action.payload,
                signInContentAuth: true
            }
        case GET_FAQS_CONTENT:
            return {
                ...state,
                faqsContent: action.payload,
                faqsContentAuth: true
            }
        case GET_CONTROL_PANEL_CONTENT:
            return {
                ...state,
                controlPanelContent: action.payload,
                controlPanelContentAuth: true
            }
        case GET_LANDING_PAGE_CONTENT:
            return {
                ...state,
                landingPageContent: action.payload,
                landingPageContentAuth: true
            }
        case GET_SETTINGS_SIDEBAR_CONTENT:
            return {
                ...state,
                settingsSidebarContent: action.payload,
                settingsSidebarAuth: true
            }
        case GET_FAV_ADS_CONTENT:
            return {
                ...state,
                favAdsContent: action.payload,
                favAdsAuth: true
            }
        case GET_PHONE_NO_CONTENT:
            return {
                ...state,
                managePhoneNumberContent: action.payload,
                phoneContentAuth: true
            }
        case GET_POST_AD_CONTENT:
            return {
                ...state,
                postAdPageContent: action.payload,
                postAdPageContentAuth: true
            }
        case GET_PUBLICATION_ADS_CONTENT:
            return {
                ...state,
                publicationAdsContent: action.payload,
                publicationAdsContentAuth: true
            }
        case GET_AD_PLANNER_CONTENT:
            return {
                ...state,
                adPlannerContent: action.payload,
                adPlannerContentAuth: true
            }
        case GET_BUY_TICKETS_CONTENT:
            return {
                ...state,
                buyTicketsPageContent: action.payload,
                buyTicketsPageContentAuth: true
            }
        case GET_BILLING_CONTENT:
            return {
                ...state,
                billingPageContent: action.payload,
                billingContentAuth: true
            }
        case GET_RENEW_ADS_CONTENT:
            return {
                ...state,
                renewAdsContent: action.payload,
                renewAdsContentAuth: true
            }
        case GET_RELOCATE_ADS_CONTENT:
            return {
                ...state,
                relocateAdsContent: action.payload,
                relocateAdsContentAuth: true
            }
        case GET_CERTIFICATE_ADS_CONTENT:
            return {
                ...state,
                certificateAdsContent: action.payload,
                certificateAdsContentAuth: true
            }
        case GET_CERTIFICATION_ADS_CONTENT:
            return {
                ...state,
                certificationAdsContent: action.payload,
                certificationAdsContentAuth: true
            }
        case GET_PROMOS_CONTENT:
            return {
                ...state,
                promosContent: action.payload,
                promosContentAuth: true
            }
        case GET_CONTACT_SUPPORT_CONTENT:
            return {
                ...state,
                contactSupportContent: action.payload,
                contactSupportContentAuth: true
            }
        case GET_AD_DETAIL_CONTENT:
            return {
                ...state,
                adDetailContent: action.payload,
                adDetailContentAuth: true
            }
        case GET_GENERATED_BILL_CONTENT:
            return {
                ...state,
                generatedBillContent: action.payload,
                generatedBillContentAuth: true
            }
        case GET_REUBICATE_ADS_CONTENT:
            return {
                ...state,
                reubicateAdsContentContent: action.payload,
                reubicateAdsContentAuth: true
            }
        case GET_PUBLICATE_ADS_CONTENT:
            return {
                ...state,
                publicateAdsContent: action.payload,
                publicateAdsContentAuth: true
            }
        case GET_REPLAN_AD_CONTENT:
            return {
                ...state,
                replanAdContent: action.payload,
                replanAdContentAuth: true
            }
        case GET_ALL_CATEGORIES_CONTENT:
            return {
                ...state,
                allCategoriesContent: action.payload,
                allCategoriesContentAuth: true
            }
        case GET_PREVIEW_AD_CONTENT:
            return {
                ...state,
                previewAdContent: action.payload,
                previewAdContentAuth: true
            }
        case GET_FILTERED_ADS_CONTENT:
            return {
                ...state,
                filteredAdContent: action.payload,
                filteredAdContentAuth: true
            }
        case GET_LOI_CONTENT:
            return {
                ...state,
                loiPageContent: action.payload,
                loiContentAuth: true
            }
        case GET_BONUS_PAYMENT_CONTENT:
            return {
                ...state,
                bonusPaymentContent: action.payload,
                bonusPaymentContentAuth: true
            }
        case GET_NOTICE_CONTENT:
            return {
                ...state,
                noticeContent: action.payload,
                noticeContentAuth: true
            }
        case GET_PAYMENT_CONTENT:
            return {
                ...state,
                paymentContent: action.payload,
                paymentContentAuth: true
            }

        case BEFORE_CONTENT:
            return {
                ...state,
                pageContent: {},
                pageContentAuth: false,
                lastAdsPageContentAuth: false,
            }
        case BEFORE_AD_CONTENT:
            return {
                ...state,
                adPageContentAuth: false,
            }
        case BEFORE_WELCOME_PACK_CONTENT:
            return {
                ...state,
                welcomePackContentAuth: false,
            }
        case BEFORE_AD_COMPONENT_CONTENT:
            return {
                ...state,
                adComponentPageContentAuth: false
            }
        case BEFORE_BANNER_CONTENT:
            return {
                ...state,
                promotionalBannerContentAuth: false
            }
        case BEFORE_PASSWORD_MANAGEMENT:
            return {
                ...state,
                passwordManagementContentAuth: false,
            }
        case BEFORE_TRUST_CERTIFICATION:
            return {
                ...state,
                trustCertificationContentAuth: false,
            }
        case BEFORE_HEADER_CONTENT:
            return {
                ...state,
                headerPageContentAuth: false,
            }
        case BEFORE_FOOTER_CONTENT:
            return {
                ...state,
                footerPageContentAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}