import { BEFORE_LANDING, LANDING_PAGE_DATA } from '../../redux/types';

const initialState = {
    favAdList: {},
    getfavAdList: false,
    UnfavouriteAdAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LANDING_PAGE_DATA:
            return {
                ...state,
                landingPageDataAuth: true,
                landingPageData: action.payload
            }
        case BEFORE_LANDING:
            return {
                ...state,
                landingPageDataAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}