import { SEND_EMAIL, BEFORE_PASSWORD, RESET_PASSWORD, SET_PASSWORD, CHANGE_PASSWORD } from '../../redux/types';

const initialState = {
    forgotEmailSentAuth: false,
    resetPasswordAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SEND_EMAIL:
            return {
                ...state,
                forgotEmailSentAuth: true,
                forgotEmailSentMessage: action.payload,
            }
        case RESET_PASSWORD:
            return {
                ...state,
                resetPasswordAuth: true,
            }
        case SET_PASSWORD:
            return {
                ...state,
                setPasswordAuth: true,
                setPasswordMessage: action.payload,
            }
        case CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordAuth: true,
                changePasswordMessage: action.payload,
            }
        case BEFORE_PASSWORD:
            return {
                ...state,
                forgotEmailSentAuth: false,
                resetPasswordAuth: false,
                setPasswordAuth: false,
                changePasswordAuth: false
            }
        default:
            return {
                ...state
            }
    }
}