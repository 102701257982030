import { BEFORE_TICKETS, FREE_TICKETS, BUY_TICKETS } from '../../redux/types'

const initialState = {
    freeTickets: {},
    freeTicketsAuth: false,
    buyTickets: {},
    buyTicketsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FREE_TICKETS:
            return {
                ...state,
                freeTickets: action.payload,
                freeTicketsAuth: true
            }
        case BUY_TICKETS:
            return {
                ...state,
                buyTickets: action.payload,
                buyTicketsAuth: true
            }
        case BEFORE_TICKETS:
            return {
                freeTickets: {},
                freeTicketsAuth: false,
                buyTickets: {},
                buyTicketsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}