import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import Avatar from "../../../assets/images/avatar.png"
import Avatar2 from "../../../assets/images/avatar2.png"
import Avatar3 from "../../../assets/images/avatar3.png"
import Avatar4 from "../../../assets/images/avatar4.png"
import Avatar5 from "../../../assets/images/avatar5.png"
import Avatar6 from "../../../assets/images/avatar6.png"
import Ad1 from "../../../assets/images/ad1.png"
import Ad2 from "../../../assets/images/ad2.png"
import Ad3 from "../../../assets/images/ad3.png"
import Ad4 from "../../../assets/images/ad4.png"
import Ad5 from "../../../assets/images/ad5.png"
import "./home.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faMagic, faMessage } from "@fortawesome/free-solid-svg-icons";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";


function MessageList() {
    const history = useHistory();

    return (
        <div className="messages-list">
            <Container >
                <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                    <h2 className="mb-3">Ads with Conversations: 10</h2>
                    <Button className="btn btn-blue-grad" onClick={()=>history.push("/chat/blocked-users")}> Blocked Users</Button>
                </div>
                <p>The conversation will be avaliable as the ads are current and the messages have not expired.</p>

                <Row>
                    <Col md={6}>
                        <div className="card position-relative">
                            <div class="ribbon ribbon-top-right"><span>New!</span></div>
                            <div className="d-flex flex-md-nowrap  flex-wrap justify-content-between ">
                                <div className="d-flex flex-wrap">
                                <div className="ad-img mr-2 mb-sm-0 mb-2">
                                    <img src={Ad1} />
                                </div>
                                <div>
                                    <strong className="d-block mb-2">Lorem ipsum dolor sit amet</strong>
                                    <div className="d-flex align-items-center"><strong className="mr-2">Messages </strong><span className="counter">3</span> </div>
                                    <span className="d-block mb-1"><strong >User:</strong> H. Rackham</span>
                                    <span className="d-block mb-1"><strong >City:</strong> Lahore</span>
                                    <span className=" mb-2"><strong>Last: </strong>  yyyy/mm/dd hh:mm</span>

                                </div>
                                </div>
                            </div>
                            <div className="right-end">
                                <div className="ms-auto"><button className="btn btn-blue-grad" onClick={()=>history.push('/chat/add-messages')}>List Conversations</button></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="card position-relative">
                          
                            <div className="d-flex flex-md-nowrap  flex-wrap justify-content-between ">
                                <div className="d-flex flex-wrap">
                                <div className="ad-img mr-2 mb-sm-0 mb-2">
                                    <img src={Ad2} />
                                </div>
                                <div>
                                    <strong className="d-block mb-2">Lorem ipsum dolor sit amet</strong>
                                    <div className="d-flex align-items-center"><strong className="mr-2">Messages </strong><span className="counter">3</span> </div>
                                    <span className="d-block mb-1"><strong >User:</strong> H. Rackham</span>
                                    <span className="d-block mb-1"><strong >City:</strong> Lahore</span>
                                    <span className=" mb-2"><strong>Last: </strong> 
                                <FontAwesomeIcon className="warning-icon" icon={faExclamationTriangle} />
                                yyyy/mm/dd hh:mm</span>

                                </div>
                                </div>
                            </div>
                            <div className="right-end">
                                <div className="ms-auto"><button className="btn btn-blue-grad" onClick={()=>history.push('/chat/add-messages')}>List Conversations</button></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="card position-relative">
                          
                            <div className="d-flex flex-md-nowrap  flex-wrap justify-content-between ">
                                <div className="d-flex flex-wrap">
                                <div className="ad-img mr-2 mb-sm-0 mb-2">
                                    <img src={Ad3} />
                                </div>
                                <div>
                                    <strong className="d-block mb-2">Lorem ipsum dolor sit amet</strong>
                                    <div className="d-flex align-items-center"><strong className="mr-2">Messages </strong><span className="counter">3</span> </div>
                                    <span className="d-block mb-1"><strong >User:</strong> H. Rackham</span>
                                    <span className="d-block mb-1"><strong >City:</strong> Lahore</span>
                                    <span className=" mb-2"><strong>Last: </strong> 
                                <FontAwesomeIcon className="warning-icon" icon={faExclamationTriangle} />
                                yyyy/mm/dd hh:mm</span>

                                </div>
                                </div>
                            </div>
                            <div className="right-end">
                                <div className="ms-auto"><button className="btn btn-blue-grad" onClick={()=>history.push('/chat/add-messages')}>List Conversations</button></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="card position-relative">
                            <div class="ribbon ribbon-top-right"><span>New!</span></div>
                            <div className="d-flex flex-md-nowrap  flex-wrap justify-content-between ">
                                <div className="d-flex flex-wrap">
                                <div className="ad-img mr-2 mb-sm-0 mb-2">
                                    <img src={Ad4} />
                                </div>
                                <div>
                                    <strong className="d-block mb-2">Lorem ipsum dolor sit amet</strong>
                                    <div className="d-flex align-items-center"><strong className="mr-2">Messages </strong><span className="counter">3</span> </div>
                                    <span className="d-block mb-1"><strong >User:</strong> H. Rackham</span>
                                    <span className="d-block mb-1"><strong >City:</strong> Lahore</span>
                                    <span className=" mb-2"><strong>Last: </strong>  yyyy/mm/dd hh:mm</span>

                                </div>
                                </div>
                            </div>
                            <div className="right-end">
                                <div className="ms-auto"><button className="btn btn-blue-grad" onClick={()=>history.push('/chat/add-messages')}>List Conversations</button></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="card position-relative">
                            <div class="ribbon ribbon-top-right"><span>New!</span></div>
                            <div className="d-flex flex-md-nowrap  flex-wrap justify-content-between ">
                                <div className="d-flex flex-wrap">
                                <div className="ad-img mr-2 mb-sm-0 mb-2">
                                    <img src={Ad1} />
                                </div>
                                <div>
                                    <strong className="d-block mb-2">Lorem ipsum dolor sit amet</strong>
                                    <div className="d-flex align-items-center"><strong className="mr-2">Messages </strong><span className="counter">3</span> </div>
                                    <span className="d-block mb-1"><strong >User:</strong> H. Rackham</span>
                                    <span className="d-block mb-1"><strong >City:</strong> Lahore</span>
                                    <span className=" mb-2"><strong>Last: </strong>  yyyy/mm/dd hh:mm</span>

                                </div>
                                </div>
                            </div>
                            <div className="right-end">
                                <div className="ms-auto"><button className="btn btn-blue-grad" onClick={()=>history.push('/chat/add-messages')}>List Conversations</button></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="card position-relative">
                            <div class="ribbon ribbon-top-right"><span>New!</span></div>
                            <div className="d-flex flex-md-nowrap  flex-wrap justify-content-between ">
                                <div className="d-flex flex-wrap">
                                <div className="ad-img mr-2 mb-sm-0 mb-2">
                                    <img src={Ad2} />
                                </div>
                                <div>
                                    <strong className="d-block mb-2">Lorem ipsum dolor sit amet</strong>
                                    <div className="d-flex align-items-center"><strong className="mr-2">Messages </strong><span className="counter">3</span> </div>
                                    <span className="d-block mb-1"><strong >User:</strong> H. Rackham</span>
                                    <span className="d-block mb-1"><strong >City:</strong> Lahore</span>
                                    <span className=" mb-2"><strong>Last: </strong>  yyyy/mm/dd hh:mm</span>

                                </div>
                                </div>
                            </div>
                            <div className="right-end">
                                <div className="ms-auto"><button className="btn btn-blue-grad" onClick={()=>history.push('/chat/add-messages')}>List Conversations</button></div>
                            </div>
                        </div>
                    </Col>

                </Row>
                <div className="bottom-btn-section text-center pt-0 pt-sm-3 pt-md-5">
                        <button className="btn-blue-grad d-inline-block align-top" type="button" disabled={(false)}>
                            <div className="w-100 d-flex justify-content-center align-items-center">
                                <span>Load More</span>
                                {/* {smallLoader ?
                                    <SmallLoader />
                                    : ''
                                } */}
                            </div>  
                        </button>
                    </div>


            </Container>

        </div>
    )
}
export default MessageList