import { EMPTY_ERRORS, GET_ERRORS, BEFORE_CONTENT, GET_CONTENT, GET_HEADER_CONTENT, GET_POPULAR_CATEGORIES_CONTENT, GET_FEATURE_ADS_CONTENT, GET_LAST_ADS_CONTENT, GET_FOOTER_CONTENT, GET_PROFILE_CONTENT, BEFORE_HEADER_CONTENT, BEFORE_FOOTER_CONTENT, GET_AD_CONTENT, BEFORE_AD_CONTENT, BEFORE_AD_COMPONENT_CONTENT, GET_AD_COMPONENT_CONTENT, GET_PASSWORD_MANAGEMENT_CONTENT, BEFORE_PASSWORD_MANAGEMENT, BEFORE_TRUST_CERTIFICATION, GET_TRUST_CERTIFICATION_CONTENT, BEFORE_BANNER_CONTENT, GET_BANNER_CONTENT, GET_WELCOME_PACK_CONTENT, BEFORE_WELCOME_PACK_CONTENT, GET_SIGN_IN_CONTENT, GET_FAQS_CONTENT, GET_CONTROL_PANEL_CONTENT, GET_LANDING_PAGE_CONTENT, GET_SETTINGS_SIDEBAR_CONTENT, GET_FAV_ADS_CONTENT, GET_PHONE_NO_CONTENT, GET_POST_AD_CONTENT, GET_PUBLICATION_ADS_CONTENT, GET_AD_PLANNER_CONTENT, GET_BUY_TICKETS_CONTENT, GET_BILLING_CONTENT, GET_RENEW_ADS_CONTENT, GET_RELOCATE_ADS_CONTENT, GET_CERTIFICATE_ADS_CONTENT, GET_CERTIFICATION_ADS_CONTENT, GET_PROMOS_CONTENT, GET_CONTACT_SUPPORT_CONTENT, GET_AD_DETAIL_CONTENT, GET_GENERATED_BILL_CONTENT, GET_REUBICATE_ADS_CONTENT, GET_PUBLICATE_ADS_CONTENT, GET_REPLAN_AD_CONTENT, GET_ALL_CATEGORIES_CONTENT, GET_PREVIEW_AD_CONTENT, GET_FILTERED_ADS_CONTENT, GET_LOI_CONTENT, GET_BONUS_PAYMENT_CONTENT, GET_PAYMENT_CONTENT, GET_NOTICE_CONTENT } from '../../redux/types'
import { ENV } from './../../config/config'

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeContent = () => {
    return {
        type: BEFORE_CONTENT
    }
}

export const beforeWelcomePack = () => {
    return {
        type: BEFORE_WELCOME_PACK_CONTENT
    }
}

export const beforeHeaderContent = () => {
    return {
        type: BEFORE_HEADER_CONTENT
    }
}

export const beforeFooterContent = () => {
    return {
        type: BEFORE_FOOTER_CONTENT
    }
}

export const beforePasswordManagementContent = () => {
    return {
        type: BEFORE_PASSWORD_MANAGEMENT
    }
}

export const beforeTrustCertificationContent = () => {
    return {
        type: BEFORE_TRUST_CERTIFICATION
    }
}

export const beforeBannerContent = () => {
    return {
        type: BEFORE_BANNER_CONTENT
    }
}

export const beforeAdContent = () => {
    return {
        type: BEFORE_AD_CONTENT
    }
}

export const beforeAdComponentContent = () => {
    return {
        type: BEFORE_AD_COMPONENT_CONTENT
    }
}

export const getContent = (page = '') => async dispatch => {
    dispatch(emptyError());

    try {
        const location = await ENV.getLatLng();

        if (location.status === "OK") {
            const { lat, lng } = location;
            const url = `${ENV.url}content/${page}?lat=${lat}&lng=${lng}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
                    "Authorization": ENV.Authorization,
                    "x-auth-token": ENV.x_auth_token,
                    'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
                }
            });
            const data = await response.json();
            
            dispatch({
                type: GET_CONTENT,
                payload: data,
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: 'Failed to get location.'
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERRORS,
            payload: error,
        });
    }
}
export const getHeaderContent = (page = '') => async dispatch => {
    dispatch(emptyError());

    try {
        const location = await ENV.getLatLng();

        if (location.status === "OK") {
            const { lat, lng } = location;
            const url = `${ENV.url}content/${page}?lat=${lat}&lng=${lng}`; 

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
                    "Authorization": ENV.Authorization,
                    "x-auth-token": ENV.x_auth_token,
                    'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
                }
            });

            const data = await response.json();

            dispatch({
                type: GET_HEADER_CONTENT,
                payload: data,
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: 'Failed to get location.',
            });
        }
    } catch (errors) {
        dispatch({
            type: GET_ERRORS,
            payload: errors,
        });
    }
};

export const getPopularCategoriesContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
    const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_POPULAR_CATEGORIES_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getFeatureAdsContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_FEATURE_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getLastAdsContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_LAST_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getFooterContent = (page = '') => async (dispatch) => {
    dispatch(emptyError())
    const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_FOOTER_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getProfileContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PROFILE_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getAdContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_AD_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getAdComponentContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_AD_COMPONENT_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPasswordManagementContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PASSWORD_MANAGEMENT_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getTrustCertificationContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_TRUST_CERTIFICATION_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getBannerContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_BANNER_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getWelcomePackContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_WELCOME_PACK_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getSignInContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_SIGN_IN_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getFaqsContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_FAQS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getControlPanelContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_CONTROL_PANEL_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getLandingPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_LANDING_PAGE_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getSettingsSidebarContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_SETTINGS_SIDEBAR_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getFavAdsContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_FAV_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPhoneNumbersContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PHONE_NO_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPostAdPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_POST_AD_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPublicationAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PUBLICATION_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getAdPlannerPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_AD_PLANNER_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getBuyTicketsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_BUY_TICKETS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getBillingPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_BILLING_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getRenewAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_RENEW_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getRelocateAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_RELOCATE_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getCertificateAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_CERTIFICATE_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getCertificationAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_CERTIFICATION_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPromosPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PROMOS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getContactSupportPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_CONTACT_SUPPORT_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getAdDetailPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_AD_DETAIL_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getGeneratedBillPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_GENERATED_BILL_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getReubicateAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_REUBICATE_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPublicateAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PUBLICATE_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getReplanAdsPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_REPLAN_AD_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getAllCategoriesPageContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_ALL_CATEGORIES_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPreviewAdContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PREVIEW_AD_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getFilteredAdContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_FILTERED_ADS_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getLoiContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_LOI_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getBonusPaymentContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_BONUS_PAYMENT_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getPaymentContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_PAYMENT_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const getNoticeContent = (page = '') => async(dispatch) => {
    dispatch(emptyError())
     const urlWithLatLng = await getLatLngUrl(`${ENV.url}content/${page}`);
    fetch(urlWithLatLng, {
        method: 'GET',
        headers: {
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : '',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            dispatch({
                type: GET_NOTICE_CONTENT,
                payload: data,
            })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}
export const getLatLngUrl = async (url) => {
    const location = await ENV.getLatLng();
    
    if (location.status === "OK") {
        const { lat, lng } = location;
        return `${url}?lat=${lat}&lng=${lng}`;
    } else {
        throw new Error("Failed to get location");
    }
};
