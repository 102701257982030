import React, { Component } from "react";
import { connect } from 'react-redux';
import Mainheader from "./../components/main-header/main-header";
import Mainfooter from "./../components/main-footer/main-footer";
import { ENV } from './../config/config';
import { emptyError } from './../redux/Shared/shared.action';

class Auth extends Component {
  componentDidMount() {
    let userData = ENV.getUserKeys();
    let pathname = this.props.location.pathname;
    if (!userData || !userData.email) {
      if (pathname !== "/" && pathname !== "/faqs" && pathname.indexOf("/cms/") < 0 && pathname.indexOf("/items") < 0 && pathname.indexOf("/ad-detail") && pathname.indexOf("/terms-of-services") < 0 && pathname.indexOf("/privacy-policy") < 0 && pathname.indexOf("/about-us") < 0 && pathname.indexOf("/all-categories") < 0 && pathname.indexOf("/promo-details") < 0) {
        // ENV.clearStorage();
        this.props.emptyError();
        this.props.history.push('/');
        return;
      }
    }
  }

  componentDidUpdate(e) {

    if (this.props.errors && (this.props.errors.forceReset || this.props.errors.userDisabled || this.props.errors.deleteUser || this.props.errors.forceLogout || this.props.errors.inactiveAccount || this.props.errors.blocked)) {
      let url = '/';
      if (this.props.errors.forceReset) {
        url = '/reset/' + this.props.errors.userId;
      }
      ENV.clearStorage();
      this.props.history.push(url);
      this.props.emptyError();
      return;
    }

    
  }

  render() {
    return (
      <React.Fragment>
        <Mainheader />
        {this.props.children}
        <Mainfooter />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(mapStateToProps, { emptyError })(Auth);