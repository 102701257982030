import { BEFORE_BANNER, GET_BANNER, GET_BANNER_LIST, GET_NORMAL_BANNER_LIST, HIDE_NORMAL_BANNERS, BANNER_SPENT_TIME, UPDATE_BANNER_STATS, UPDATE_PROMO_BANNER_STATS } from '../../redux/types'

const initialState = {
    banner: {},
    getBannerAuth: false,
    bannerList: [],
    getBannerListAuth: false,
    hideNormalBanners: false,
    normalBanners: [],
    getNormalBannersAuth: false,
    getBannerSpentTimeAuth: false,
    bannerStatsAuth: false,
    promoBannerStatsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BANNER:
            return {
                ...state,
                banner: action.payload,
                getBannerAuth: true
            }
        case GET_BANNER_LIST:
            return {
                ...state,
                bannerList: action.payload,
                getBannerListAuth: true
            }
        case GET_NORMAL_BANNER_LIST:
            return {
                ...state,
                normalBanners: action.payload,
                getNormalBannersAuth: true
            }
        case HIDE_NORMAL_BANNERS:
            return {
                ...state,
                hideNormalBanners: true
            }
        case BANNER_SPENT_TIME:
            return {
                ...state,
                getBannerSpentTimeAuth: true
            }
        case UPDATE_BANNER_STATS:
            return {
                ...state,
                bannerStatsAuth: true
            }
        case UPDATE_PROMO_BANNER_STATS:
            return {
                ...state,
                promoBannerStatsAuth: true
            }
        case BEFORE_BANNER:
            return {
                banner: {},
                getBannerAuth: false,
                bannerList: [],
                getBannerListAuth: false,
                normalBanners: [],
                getNormalBannersAuth: false,
                getBannerSpentTimeAuth: false,
                bannerStatsAuth: false,
                promoBannerStatsAuth: false

            }
        default:
            return {
                ...state
            }
    }
}