import { GET_PAGE_ACTION, BEFORE_PAGE } from '../../redux/types';

const initialState = {
    cmsPageAuth: false,
    cmsPageData: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAGE_ACTION:
            return {
                ...state,
                cmsPageAuth: true,
                cmsPageData: action.payload
            }
        case BEFORE_PAGE:
            return {
                ...state,
                cmsPageAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}