import { BEFORE_SHARED, SET_USER_DATA, SET_ACTIVE_USER_DATA, SET_COUNTRY_LANGUAGE_DATA, ADD_COUNTRY_REQUEST, SUBMIT_CONTACT_SUPPORT_REQUEST, COUNTRY_CODE_DATA, SET_USER_LOCATION, SITE_DATA,SETTINGS_DATA } from '../types';

const initialState = {
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_LOCATION:
            return {
                ...state,
                userLocationDataAuth: true,
                userLocationData: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                loggedInUserData: action.payload,
                loggedInUserAuth: true
            }
        case SET_ACTIVE_USER_DATA:
            return {
                ...state,
                activeUserType: action.payload
            }
        case SET_COUNTRY_LANGUAGE_DATA:
            return {
                ...state,
                activeUserCountryLangAuth: true,
                activeUserCountry: action.payload,
                activeUserLanguage: action.payload
            }
        case ADD_COUNTRY_REQUEST:
            return {
                ...state,
                addUserCountryAuth: true,
                addUserCountry: action.payload
            }
        case SUBMIT_CONTACT_SUPPORT_REQUEST:
            return {
                ...state,
                submitSupportRequestAuth: true,
                submitSupportData: action.payload,
            }
        case COUNTRY_CODE_DATA:
            return {
                ...state,
                countryCodeData: action.payload,
            }
        case SITE_DATA:
            return {
                ...state,
                siteDateAuth: true,
                siteDate: action.payload,
            }
            case SETTINGS_DATA:
            return {
                ...state,
                settingsDataAuth: true,
                settingsData: action.payload,
            }
        case BEFORE_SHARED:
            return {
                ...state,
                submitSupportRequestAuth: false,
                settingsDataAuth: false,
                addUserCountryAuth: false,
                userLocationDataAuth: false,
                loggedInUserAuth: false
            }
        default:
            return {
                ...state
            }
    }
}