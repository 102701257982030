import { BEFORE_PROFILE, USER_UPDATED_SUCCESSFULLY, UPDATE_EMAIL_REQUEST, UPDATE_EMAIL_SUBMIT, REQUEST_TRUST_CERT_SUBMIT, CHECK_TRUST_CERT, GET_PROFILE_TEXT } from '../../redux/types';

const initialState = {
    updateProfileAuth: false,
    getProfileText: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_UPDATED_SUCCESSFULLY:
            return {
                ...state,
                updateProfileAuth: true,
                updateProfileMessage: action.payload,
            }
        case UPDATE_EMAIL_REQUEST:
            return {
                ...state,
                updateEmailRequestAuth: true,
                updateEmailRequestMessage: action.payload,
            }
        case UPDATE_EMAIL_SUBMIT:
            return {
                ...state,
                updateEmailAuth: true,
                updateEmailData: action.payload,
            }
        case CHECK_TRUST_CERT:
            return {
                ...state,
                checkTrustCertAuth: true,
                checkTrustCertData: action.payload
            }
        case REQUEST_TRUST_CERT_SUBMIT:
            return {
                ...state,
                requestTrustCertAuth: true,
                requestTrustCertMessage: action.payload,
            }
        case GET_PROFILE_TEXT:
            return {
                ...state,
                profileText: action.payload,
                getProfileText: true
            }
        case BEFORE_PROFILE:
            return {
                ...state,
                requestTrustCertAuth: false,
                updateProfileAuth: false,
                updateEmailAuth: false,
                updateEmailRequestAuth: false,
                getProfileText: false

            }
        default:
            return {
                ...state
            }
    }
}