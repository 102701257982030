import { EMPTY_ERRORS, BEFORE_LOGIN, LOGIN_ACTION, REGISTER_ACTION, SET_USER_DATA, RESET_REQUIRED, GET_ERRORS,BLOCKED_USER } from './../../redux/types';
import { ENV } from './../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeLogin = () => {
    return {
        type: BEFORE_LOGIN
    }
}

export const loginAction = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'auth/login', {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        console.log(data,"data")
        if (data.status) {
            ENV.encryptUserData(data.data);
            dispatch({
                type: LOGIN_ACTION,
                payload: data?.requiredPhoneValidation
            });
            dispatch({
                type: SET_USER_DATA,
                payload: data.data
            })
        } else {
            if (data.forceReset) {
                dispatch({
                    type: RESET_REQUIRED,
                    payload: data.userId
                })
            }
            if (data.blocked) {
                console.log(data.blocked,"data.blocked")
                dispatch({
                    type: BLOCKED_USER,
                    payload: data.blocked
                })
            }
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const registerAction = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'auth/register', {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: REGISTER_ACTION,
                payload: data.message
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}