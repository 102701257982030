import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import './SmallLoader.css';

function SmallLoader(props) {
	const [componentInitialCall, setInitialCall] = useState(true)
	useEffect(() => {
		if (componentInitialCall) {
			AOS.init();
			setInitialCall(false);
		}
	}, [componentInitialCall]);
	return (
		<React.Fragment>
			<div className="small-loader-holder">
				<div className="small-loader"></div>
			</div>
		</React.Fragment>
	);
}
export default SmallLoader;