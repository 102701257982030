import { BEFORE_BONUS_PAYMENTS, GET_BONUS_PAYMENTS } from '../../redux/types'

const initialState = {
    bonusPayments: [],
    getBonusPaymentsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BONUS_PAYMENTS:
            return {
                ...state,
                bonusPayments: action.payload,
                getBonusPaymentsAuth: true
            }
        case BEFORE_BONUS_PAYMENTS:
            return {
                bonusPayments: [],
                getBonusPaymentsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}