import React from "react";
import Avatar from "../../../assets/images/chat-avatar.png"
import { faPaperPlane, faExclamationTriangle, faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Col, Container, Row } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './home.css'
import Pagination from 'react-bootstrap/Pagination';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../chatbox/chatbox.css'
import { useHistory } from "react-router-dom";

function AddMessages() {
    const history = useHistory();
    return (

        <div className="message-list">
            < Container >
                <div className="top-head">
                    <div className="d-flex justify-content-between flex-wrap">
                        <h2>Current Conversations: 5</h2>
                        <div>
                            <button className="btn btn-blue-grad " onClick={()=>history.push('/chat/messages-list')}>List Ads with Chat</button>
                        </div>
                    </div>
                    <p>This Conversation will be available as the ads are current and the messages have not expired.</p>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="row mb-4 mb-lg-0">

                            <div className="col-xl-12 col-lg-12 col-12 pos-stat mb-2">
                                <Navbar expand="xl">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse className="collapse navbar-collapse mb-4" id="basic-navbar-nav">
                                        <div className="msg-container-header p-2 d-flex justify-content-between text-white flex-column flex-sm-row mb-3 flex-wrap">
                                            <div className="add-info-block d-flex flex-fill align-items-start flex-column flex-sm-row align-items-center ">
                                                <div className="d-flex flex-column mr-0 mr-sm-3 mb-3 mb-sm-0">
                                                    <div className="image-holder ">
                                                        <img src={Avatar} alt="" />
                                                    </div>
                                                </div>
                                                <div className="detail-holder d-flex flex-fill justify-content-center flex-column">
                                                    <h2 className="text-left text-sm-center text-white mb-3">The href attribute rquires a valide value to be accessible</h2>
                                                    <div className="details d-flex flex-wrap">
                                                        <div className="detail-row d-flex">
                                                            <strong className="detail-label mr-2">City:</strong>
                                                            <span className="detail-value">Spain</span>
                                                        </div>

                                                        <div className="detail-row d-flex">
                                                            <strong className="detail-label mr-2">End Date:</strong>
                                                            <span className="detail-value">
                                                                22-07-2022
                                                                <FontAwesomeIcon className="text-warning ml-2" icon={faExclamationTriangle} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="details d-flex flex-wrap">
                                                        <div className="detail-row d-flex mb-0">
                                                            <strong className="detail-label mr-2">Ad ID:</strong>
                                                            <span className="detail-value">HDF-285SDINV</span>
                                                        </div>
                                                        <div className="detail-row d-flex mb-0">
                                                            <button className="btn-white-outline d-flex justify-content-center align-items-center"> Renew Ad!</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-owner-block">
                                                <div className="image-holder mb-sm-3 ml-sm-3 position-relative text-white">
                                                    {/* <img className="img-fluid" src={Avatar} alt="" />
                                                      <span className="owner-name d-block text-center position-static position-sm-absolute">Umer Surkhail</span> */}
                                                </div>
                                            </div>
                                        </div>

                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="conversation-detail">
                    <Row>
                        <Col className="mb-2" md={12}>
                            <div className="card">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center flex-wrap">

                                        <div className="position-relative">
                                            <div className="avatar-holder mr-3 ">

                                                <img className="img-fluid " src={Avatar} alt="" />

                                            </div>
                                            <span className="message-counter">3</span>
                                        </div>

                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <strong className="d-block mb-2">User Name</strong>

                                            </div>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="float-end d-block pb-2 date  pt-0 ">yyyy/mm/dd hh:mm</span>
                                        <div className="
                                       pt-2 pt-md-0 pt-sm-0">
                                            <button className="btn btn-blue-grad " onClick={()=>history.push('/chat/62b1b9cdc89fb221b75bb28c/62b1b9bec89fb221b75bb288')}>
                                                Read Conversation
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-2" md={12}>
                            <div className="card">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center flex-wrap">

                                        <div className="position-relative">
                                            <div className="avatar-holder mr-3 ">

                                                <img className="img-fluid " src={Avatar} alt="" />

                                            </div>
                                            <span className="message-counter">7</span>
                                        </div>

                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <strong className="d-block mb-2">User Name</strong>

                                            </div>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="float-end d-block pb-2 date pt-0 ">yyyy/mm/dd hh:mm</span>
                                        <div className="
                                       pt-2 pt-md-0 pt-sm-0">
                                            <button className="btn btn-blue-grad " onClick={()=>history.push('/chat/62b1b9cdc89fb221b75bb28c/62b1b9bec89fb221b75bb288')}>
                                                Read Conversation
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-2" md={12}>
                            <div className="card">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center flex-wrap">

                                        <div className="position-relative">
                                            <div className="avatar-holder mr-3 ">

                                                <img className="img-fluid " src={Avatar} alt="" />

                                            </div>
                                            <span className="message-counter">10</span>
                                        </div>

                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <strong className="d-block mb-2">User Name</strong>

                                            </div>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="float-end d-block pb-2 date  pt-0 ">yyyy/mm/dd hh:mm</span>
                                        <div className="
                                       pt-2 pt-md-0 pt-sm-0">
                                            <button className="btn btn-blue-grad " onClick={()=>history.push('/chat/62b1b9cdc89fb221b75bb28c/62b1b9bec89fb221b75bb288')}>
                                                Read Conversation
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-2" md={12}>
                            <div className="card">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center flex-wrap">

                                        <div className="position-relative">
                                            <div className="avatar-holder mr-3 ">

                                                <img className="img-fluid " src={Avatar} alt="" />

                                            </div>
                                            <div className="message-counter"><span>20</span></div>
                                        </div>

                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <strong className="d-block mb-2">User Name</strong>

                                            </div>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="float-end d-block pb-2 date pt-0 ">yyyy/mm/dd hh:mm</span>
                                        <div className="
                                       pt-2 pt-md-0 pt-sm-0">
                                            <button className="btn btn-blue-grad " onClick={()=>history.push('/chat/62b1b9cdc89fb221b75bb28c/62b1b9bec89fb221b75bb288')}>
                                                Read Conversation
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>
                <div className="bottom-btn-section text-center pt-0 pt-sm-3 pt-md-5 mb-3">
                    <a className="btn-blue-grad d-inline-block align-top" href="/last-ads">
                        <span>Load More</span>
                    </a>
                </div>

            </Container >
        </div >
    )
}
export default AddMessages