import {
    BEFORE_FAQ,
    GET_FAQ_LIST,
} from '../../redux/types';

const initialState = {
    faqList: {},
    getfaqList: false
}
export default function (state = initialState, action) {
    switch (action.type) {

        case GET_FAQ_LIST:
            return {
                ...state,
                faqList: action.payload,
                getfaqList: true
            }
        case BEFORE_FAQ:
            return {
                ...state,
                getfaqList: false
            }

        default:
            return {
                ...state
            }
    }
}
