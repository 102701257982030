import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faExclamation } from '@fortawesome/free-solid-svg-icons'
// import { faSmile } from '@fortawesome/free-regular-svg-icons'
// import { faFrown } from '@fortawesome/free-regular-svg-icons'
import store from './store';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from 'react-alert-template-basic'

const AlertTemplate = ({ style, options, message, close }) => (
  <div className="custom-react-alret" style={style}>
    {/* {options.type === 'info' && <span className="icon-holder mr-2"><FontAwesomeIcon icon={faExclamation} /></span>}
    {options.type === 'success' && <span className="icon-holder mr-2"><FontAwesomeIcon icon={faSmile} /></span>}
    {options.type === 'error' && <span className="icon-holder mr-2"><FontAwesomeIcon icon={faFrown} /></span>} */}
    {message}
    <button onClick={close}>X</button>
  </div>
)

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();