import { GET_AD_FAMILIES, GET_AD_LIST, GET_AD, CREATE_AD, EDIT_AD, PREVIEW_AD, DELETE_AD, GET_AD_ONLY, AD_CERTIFICATION_REQUEST, AD_PLAN_REQUEST, EMPTY_AD_LIST, GET_FAV_AD, BEFORE_ADS, UNFAV_AD, EMPTY_CERTIFICATION_AD_LIST, GET_CERTIFICATION_AD_LIST, EMPTY_FAV_AD, LIST_ALL_FAMILIES, EMPTY_ALL_FAMILIES, GET_AD_PARTICULAR, EMAIL_SENT_TO_PUBLISHER, CREATE_FAVOURITE_AD, CREATE_RATE_AD, CREATE_REPORT_AD, GET_RENEW_AD_LIST, EMPTY_RENEW_AD_LIST, EMPTY_AD_PLANNER_LIST, GET_AD_PLANNER_LIST, GET_PUBLICATION_ADS_LIST, EMPTY_PUBLICATION_ADS_LIST, PAUSE_AD, GET_RELOCATE_ADS_LIST, EMPTY_RELOCATE_ADS_LIST, FILTERED_ADS_LIST, AD_STATS, AD_BEFORE_DATA_HOOK, GET_AD_SETTINGS, RELOCATE_AD, RENEW_AD, EXTEND_AD, EMPTY_FILTERED_ADS_LIST, PAYNOW_AD, RENEW_FREE_AD,FETCH_RADIUS_RANGE,GET_BLOCKED_FEATURES } from '../../redux/types';

function mergeDocs(oldDocs, newDocs) {
    let data = {
        limit: newDocs.limit,
        page: newDocs.page,
        total: newDocs.total,
        pages: newDocs.pages
    }
    let mergedDocs = [];
    if (oldDocs && oldDocs.docs) {
        mergedDocs = oldDocs.docs;
        mergedDocs.push(...newDocs.docs)
    }
    else {
        mergedDocs = newDocs.docs;
    }
    data.docs = mergedDocs;
    return data;
}

const initialState = {
    favAdList: {},
    getfavAdList: false,
    UnfavouriteAdAuth: false,
    createAdStatsAuth: false,
    adSettingsAuth: false,
    renewFreeAdAuth: false,
    fetchRadiusRangeAuth: false,
    blockedFeatures: false,
    adSettings: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_AD_FAMILIES:
            let updateState = {
                ...state,
                getAdsFamiliesAuth: true
            }
            if (action.payload.families) {
                updateState.adFamilies = action.payload.families
            }
            if (action.payload.phones) {
                updateState.adPhones = action.payload.phones
            }
            if (action.payload.settings) {
                updateState.adSettings = action.payload.settings
            }
            if (action.payload.lastAddedId) {
                updateState.lastAddedId = action.payload.lastAddedId
            }
            if (action.payload.cities) {
                updateState.cities = action.payload.cities
            }
            return updateState;
        case CREATE_AD:
            return {
                ...state,
                createdAdAuth: true,
                createdAdData: action.payload
            }
        case EDIT_AD:
            return {
                ...state,
                editedAdAuth: true,
                editedAdData: action.payload
            }
        case RENEW_AD:
            return {
                ...state,
                renewAdAuth: true,
                renewAdData: action.payload
            }
        case RENEW_FREE_AD:
            return {
                ...state,
                renewFreeAdAuth: true,
                renewFreeAdData: action.payload
            }
        case PAYNOW_AD:
            return {
                ...state,
                payNowAdAuth: true,
                payNowAdData: action.payload
            }
        case EXTEND_AD:
            return {
                ...state,
                extendAdAuth: true,
                extendAdData: action.payload
            }
        case FETCH_RADIUS_RANGE:
            return {
            ...state, 
            fetchRadiusRangeAuth: true,
            radiusRangeData: action.payload
            }
        case RELOCATE_AD:
            return {
                ...state,
                relocatedAdAuth: true,
                relocatedAdData: action.payload
            }
        case PREVIEW_AD:
            return {
                ...state,
                previewAdAuth: true,
                previewAdData: action.payload,
            }
        case GET_AD:
            return {
                ...state,
                selectedAd: action.payload,
                getSelectedAd: true
            }
        case EMPTY_ALL_FAMILIES:
            return {
                ...state,
                allFamiliesList: []
            }
        case LIST_ALL_FAMILIES:
            return {
                ...state,
                allFamiliesList: mergeDocs(state.allFamiliesList, action.payload),
                allFamiliesListAuth: true
            }
        case GET_FAV_AD:
            return {
                ...state,
                favAdList: mergeDocs(state.favAdList, action.payload),
                getfavAdList: true
            }
        case EMPTY_FILTERED_ADS_LIST:
            return {
                ...state,
                filteredAdsList: []
            }
        case FILTERED_ADS_LIST:
            return {
                ...state,
                filteredAdsList: mergeDocs(state.filteredAdsList, action.payload),
                getFilteredAdsList: true
            }
        case CREATE_FAVOURITE_AD:
            return {
                ...state,
                toggleFavAdAuth: true
            }
        case CREATE_RATE_AD:
            return {
                ...state,
                createRateAdAuth: true
            }
        case CREATE_REPORT_AD:
            return {
                ...state,
                createReportAdAuth: true
            }
        case EMPTY_FAV_AD:
            return {
                ...state,
                favAdList: [],
            }
        case EMAIL_SENT_TO_PUBLISHER:
            return {
                ...state,
                emailSentToPublisherAuth: true,
                emailSentToPublisherData: action.payload
            }
        case GET_AD_ONLY:
            return {
                ...state,
                selectedAdOnly: action.payload,
                getSelectedAdOnly: true
            }
        case GET_AD_PARTICULAR:
            return {
                ...state,
                selectedAdParticular: action.payload,
                getSelectedAdParticular: true
            }
        case DELETE_AD:
            return {
                ...state,
                deleteAdAuth: true,
                adsList: action.payload?.data,
            }
        case PAUSE_AD:
            return {
                ...state,
                pauseAdAuth: true,
                pauseAdData: action.payload
            }
        case GET_AD_LIST:
            return {
                ...state,
                adsList: mergeDocs(state.adsList, action.payload),
                getAdsList: true,
                hourlyAdRelocationForFreeAd: action.payload?.hourlyAdRelocationForFreeAd || 0,
                complimentaryPaidAdDays: action.payload?.complimentaryPaidAdDays || 0,
                complimentaryFreeAdDays: action.payload?.complimentaryFreeAdDays || 0,
                freeAdRelocationHours: action.payload?.freeAdRelocationHours || 0,
            }
        case EMPTY_AD_LIST:
            return {
                ...state,
                adsList: []
            }
        case GET_CERTIFICATION_AD_LIST:
            return {
                ...state,
                adsCertificationList: mergeDocs(state.adsCertificationList, action.payload),
                getAdsCertificationList: true

            }
        case EMPTY_CERTIFICATION_AD_LIST:
            return {
                ...state,
                adsCertificationList: []
            }
        case GET_RENEW_AD_LIST:
            return {
                ...state,
                adsRenewList: mergeDocs(state.adsRenewList, action.payload),
                getAdsRenewList: true,
                hourlyAdRelocationForFreeAd: action.payload?.hourlyAdRelocationForFreeAd || 0,
                complimentaryPaidAdDays: action.payload?.complimentaryPaidAdDays || 0,
                complimentaryFreeAdDays: action.payload?.complimentaryFreeAdDays || 0,
                freeAdRelocationHours: action.payload?.freeAdRelocationHours || 0,
            }
        case EMPTY_RENEW_AD_LIST:
            return {
                ...state,
                adsRenewList: []
            }
        case GET_AD_PLANNER_LIST:
            return {
                ...state,
                adPlannerList: mergeDocs(state.adPlannerList, action.payload),
                getAdPlannerList: true,
                hourlyAdRelocationForFreeAd: action.payload?.hourlyAdRelocationForFreeAd || 0,
                complimentaryPaidAdDays: action.payload?.complimentaryPaidAdDays || 0,
                complimentaryFreeAdDays: action.payload?.complimentaryFreeAdDays || 0,
                freeAdRelocationHours: action.payload?.freeAdRelocationHours || 0,

            }
        case EMPTY_AD_PLANNER_LIST:
            return {
                ...state,
                adPlannerList: []
            }
        case GET_PUBLICATION_ADS_LIST:
            return {
                ...state,
                publicationAdsList: mergeDocs(state.publicationAdsList, action.payload),
                getPublicationAdsList: true
            }
        case EMPTY_PUBLICATION_ADS_LIST:
            return {
                ...state,
                publicationAdsList: []
            }
        case GET_RELOCATE_ADS_LIST:
            return {
                ...state,
                relocateAdsList: mergeDocs(state.relocateAdsList, action.payload),
                getRelocateAdsList: true,
                hourlyAdRelocationForFreeAd: action.payload?.hourlyAdRelocationForFreeAd || 0,
                complimentaryPaidAdDays: action.payload?.complimentaryPaidAdDays || 0,
                complimentaryFreeAdDays: action.payload?.complimentaryFreeAdDays || 0,
                freeAdRelocationHours: action.payload?.freeAdRelocationHours || 0,

            }
        case EMPTY_RELOCATE_ADS_LIST:
            return {
                ...state,
                relocateAdsList: []
            }
        case AD_CERTIFICATION_REQUEST:
            return {
                ...state,
                certificateAdRequestAuth: true,
                certificateAdRequestData: action.payload
            }
        case AD_PLAN_REQUEST:
            return {
                ...state,
                planAdRequestAuth: true,
                planAdRequestData: action.payload
            }
        case UNFAV_AD:
            return {
                ...state,
                UnfavouriteAdAuth: true,
                favAdList: action.payload
            }
        case AD_STATS:
            return {
                ...state,
                createAdStatsAuth: true
            }
        case AD_BEFORE_DATA_HOOK:
            return {
                ...state,
                beforeDataAdAuth: true,
                beforeDataAdData: action.payload
            }
            
            case GET_BLOCKED_FEATURES:
                return {
                    ...state,
                    blockedFeatures: true,
                    blockedFeature: action.payload
                }

        case BEFORE_ADS:
            return {
                ...state,
                getAdsFamiliesAuth: false,
                fetchRadiusRangeAuth: false,
                editedAdAuth: false,
                createdAdAuth: false,
                getAdsList: false,
                blockedFeatures: false,
                getAdsCertificationList: false,
                getSelectedAd: false,
                previewAdAuth: false,
                getSelectedAdOnly: false,
                certificateAdRequestAuth: false,
                planAdRequestAuth: false,
                getfavAdList: false,
                UnfavouriteAdAuth: false,
                emailSentToPublisherAuth: false,
                toggleFavAdAuth: false,
                createRateAdAuth: false,
                createReportAdAuth: false,
                pauseAdAuth: false,
                getAdsRenewList: false,
                getAdPlannerList: false,
                getPublicationAdsList: false,
                getRelocateAdsList: false,
                createAdStatsAuth: false,
                adSettingsAuth: false,
                payNowAdAuth: false,
                getFilteredAdsList: false,
                relocatedAdAuth: false,
                getSelectedAdParticular: false,
                extendAdAuth: false,
                deleteAdAuth: false,
                renewAdAuth: false,
                beforeDataAdAuth: false,
                renewFreeAdAuth: false
            }
        case GET_AD_SETTINGS:
            return {
                ...state,
                adSettingsAuth: true,
                adSettings: action.payload
            }
        default:
            return {
                ...state
            }
    }
}