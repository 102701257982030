import { GET_CMS_ACTION, BEFORE_FOOTER, CREATE_NEWS_LETTER, GET_CUSTOMER_SERVICE_LIST, GET_USER_INFO_LIST, GET_USER_DETAIL } from '../../redux/types';

const initialState = {
    cmsActionAuth: false,
    cmsData: [],
    newsLetterData: [],
    newsLetterAuth: false,
    customerServiceAuth: false,
    UserInfoAuth: false,
    userDetailAuth: false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CMS_ACTION:
            return {
                ...state,
                cmsActionAuth: true,
                cmsData: action.payload
            }
        case CREATE_NEWS_LETTER:
            return {
                ...state,
                newsLetterAuth: true,
                newsLetterData: action.payload
            }
        case GET_CUSTOMER_SERVICE_LIST:
            return {
                ...state,
                customerServiceAuth: true,
                customerServiceData: action.payload
            }
        case GET_USER_INFO_LIST:
            return {
                ...state,
                UserInfoAuth: true,
                userInfoData: action.payload
            }
        case GET_USER_DETAIL:
            return {
                ...state,
                UserDetails: action.payload,
                userDetailAuth: true
            }
        case BEFORE_FOOTER:
            return {
                ...state,
                cmsActionAuth: false,
                newsLetterAuth: false,
                customerServiceAuth: false,
                UserInfoAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}