import { BEFORE_LANGUAGE, GET_LANGUAGES } from '../../redux/types'

const initialState = {
    languages: [],
    languagesAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
                languagesAuth: true
            }
        case BEFORE_LANGUAGE:
            return {
                languages: [],
                languagesAuth: false
            }
        default:
            return {
                ...state
            }
    }
}