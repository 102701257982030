import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FullPageLoader from '../Shared/FullPageLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { beforeLanguage, getLanguages } from './languages.action'
import { loadSiteData } from '../../redux/Shared/shared.action'
import './languages.css'
import 'bootstrap-select/dist/css/bootstrap-select.css'
require('bootstrap-select')
const country = require('countryinfo');

function Languages(props) {
    const [componentInitialCall, setInitialCall] = useState(true)
    const [fullPageLoader, setFullPageLoader] = useState(true)
    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState()
    const [languageClass, setLanguageClass] = useState('')
    const [languageExists, setLanguageExists] = useState(false)


    useEffect(() => {
        if (componentInitialCall) {
            // get active langugages
            props.getLanguages()
            props.loadSiteData()
            setInitialCall(false)
        }
    }, [componentInitialCall])


    // set languages
    useEffect(() => {
        if (props.language.languagesAuth) {
            const { languages, defaultLanguage } = props.language.languages
            let changedLangugae = localStorage.getItem('languageChanged')
            setLanguages(languages)
            if (!changedLangugae) {
                setLanguage(defaultLanguage)
            }
            else {
                setLanguage(changedLangugae)
            }
            setFullPageLoader(false)
            props.beforeLanguage()
        }
    }, [props.language.languagesAuth])




    const changeLanguage = (e) => {
        e.preventDefault()
        let language = e.target.value
        localStorage.setItem('languageChanged', language)
        toggleLanguage()
        localStorage.setItem('language', language)
        setLanguage(language)

        // reload app
        window.location.reload()
    }

    const toggleLanguage = () => {
        if (languageClass === '')
            setLanguageClass('active')
        else
            setLanguageClass('')
    }



    const renderRadioButtons = () => {
        return (
            languages && languages.length > 0 &&
            <div className={`language-selector ${languageClass}`}>
                <span className="language-icon d-flex justify-content-centent align-items-center" onClick={() => toggleLanguage()}>
                    <FontAwesomeIcon icon={faGlobe} />
                </span>
                <div className="languages-holder">
                    {
                        languages.map((val, key) => {
                            return (
                                <label className="language-label" key={key}>
                                    {val.name} ({val.code.toUpperCase()})&nbsp;
                                    <input
                                        className="text-capitalize"
                                        checked={language === val.code}
                                        name="language"
                                        onChange={(e) => changeLanguage(e)}
                                        value={val.code}
                                        type="radio" />
                                    <span className="checkmark"></span>
                                </label>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    return (
        <>
            {
                fullPageLoader ?
                    <FullPageLoader />
                    :
                    renderRadioButtons()
            }
        </>
    )
}

const mapStateToProps = state => ({
    language: state.language,
    shared: state.shared
})
export default connect(mapStateToProps, { beforeLanguage, getLanguages, loadSiteData })(Languages)