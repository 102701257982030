export const predefinedContent = {
    countryMainPagesText: "Main Sections",
    mainCitiesOfCountry: "Main Cities of",
    mostSearchedCity: "The most searched in",
    footerText: "Topspot.es makes it easy for registered users to publish and manage their ads efficiently.",
    readMoreText: "Read More",
    footerUsefulLinks: "Useful Links",
    newsLetterSubscription: "NewsLetter Subscription",
    subscribeButton: "Subscribe",
    footerQuickAccess: "Quick Access",
    customerServiceScheduleText: "Telephone Customer Service",
    customerServiceScheduleHours: "Schedule",
    whatsappSupport: "Whatsapp Support",
    footerContactUs: "Contact Us",
    copyRightText: "Copyright",
    megaAdsReservedRights: "Topspot.es. All Rights Reserved",
    whatsappYesText: "Yes",
    whatsappNoText: "No",
    spainText: "Spain",
    pakistanText: "Pakistan"
}