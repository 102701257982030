import { BEFORE_PAYMENT, PAYMENT_SUCCESS } from '../../redux/types'

const initialState = {
    payment: {},
    paymentAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_SUCCESS:
            return {
                ...state,
                payment: action.payload,
                paymentAuth: true
            }
        case BEFORE_PAYMENT:
            return {
                payment: {},
                paymentAuth: false
            }
        default:
            return {
                ...state
            }
    }
}