export const predefinedContent = {
    headerContactText: "Contact Us",
    homeButton: "Home",
    faqsButton: "FAQs",
    userAreaText: "User Area",
    postAdButton: "Post Your Ad",
    loginText: "Login",
    registerText: "Register",
    controlPanelText: "Control Panel",
    createAdText: "Create Ad",
    manageAdsText: "Manage Ads",
    messageText:"Messages / Chat",
    faqsText: "FAQs",
    logoutText: "Logout",
    adText: "Ad",
    blockedText1:"We are sorry. There was a technical problem validating your account, the error is probably temporary.",
    blockedText2:"If the error persists, please try again later.",
    blockedText3:"If the validation error still occurs later, please contact us to review your issue.",
    blockedText4:"Thank you."

    
}
