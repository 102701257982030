import { BEFORE_PHONE, NUMBER_TO_VERIFY, VERIFY_BY_PIN, PIN_SENT_FOR_NUMBER, DELETE_NUMBER, UPDATE_STATUS_NUMBER, USER_PHONE_NUMBER, USER_MAIN_PHONE_NUMBER, EMPTY_PHONES_LIST } from '../../redux/types';

function mergeDocs(oldDocs, newDocs) {
    let data = {
        limit: newDocs.limit,
        page: newDocs.page,
        total: newDocs.total,
        pages: newDocs.pages
    }
    let mergedDocs = [];
    if(oldDocs && oldDocs.docs) {
        mergedDocs = oldDocs.docs;
        mergedDocs.push(...newDocs.docs)
    }
    else {
        mergedDocs = newDocs.docs;
    }
    data.docs = mergedDocs;
    return data;
}

const initialState = {
}

export default function (state = initialState, action) {
    switch (action.type) {
        case VERIFY_BY_PIN:
            return {
                ...state,
                phoneVerifyByPinAuth: true,
                phoneVerifiedData: action.payload,
            }
        case USER_PHONE_NUMBER:
            return {
                ...state,
                getPhoneNumbersAuth: true,
                userPhonesList: mergeDocs(state.userPhonesList, action.payload),
            }
        case USER_MAIN_PHONE_NUMBER:
            return {
                ...state,
                userMainPhoneAuth: true,
                userMainPhone: action.payload,
            }
        case NUMBER_TO_VERIFY:
            return {
                ...state,
                phoneAddedAuth: true,
                phoneAddedData: action.payload,
            }
        case PIN_SENT_FOR_NUMBER:
            return {
                ...state,
                pinSentForNumberAuth: true,
                pinSentForNumberPin: action.payload,
                pinSentOnNumberMessage: action.message,
                alreadyValid: action.alreadyValid
            }
        case DELETE_NUMBER:
            return {
                ...state,
                phoneNumberDeletedAuth: true,
                phoneDeletedData: action.payload,
                userPhonesList: action.payload?.list
            }
        case UPDATE_STATUS_NUMBER:
            return {
                ...state,
                phoneNumberStatusAuth: true,
                phoneStatusData: action.payload,
            }
        case EMPTY_PHONES_LIST:
            return {
                ...state,
                userPhonesList: {}
            }
        case BEFORE_PHONE:
            return {
                ...state,
                userMainPhoneAuth: false,
                phoneAddedAuth: false,
                phoneVerifyByPinAuth: false,
                pinSentForNumberAuth: false,
                phoneNumberDeletedAuth: false,
                phoneNumberStatusAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}