import { LOGIN_ACTION, REGISTER_ACTION, RESET_REQUIRED, BEFORE_LOGIN,BLOCKED_USER } from '../../redux/types';

const initialState = {
    loginActionAuth: false,
    resetRequiredAuth: false,
    blockedUser:false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_ACTION:
            return {
                ...state,
                loginActionAuth: true,
                requiredPhoneValidation: action.payload
            }
        case REGISTER_ACTION:
            return {
                ...state,
                registerActionMessage: action.payload,
                registerActionAuth: true,
            }
        case RESET_REQUIRED:
            return {
                ...state,
                resetRequiredAuth: true,
                userId: action.payload
            }
            case BLOCKED_USER:
            return {
                ...state,
                blockedUser:true
                // userId: action.payload
            }
        case BEFORE_LOGIN:
            return {
                ...state,
                loginActionAuth: false,
                resetRequiredAuth: false,
                registerActionAuth: false,
                blockedUser:false
            }
        default:
            return {
                ...state
            }
    }
}