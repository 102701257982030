import { EMPTY_ERRORS, BEFORE_FOOTER, GET_ERRORS, CREATE_NEWS_LETTER, GET_CUSTOMER_SERVICE_LIST, GET_USER_INFO_LIST, GET_USER_DETAIL } from './../../redux/types';
import { ENV } from './../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeFooter = () => {
    return {
        type: BEFORE_FOOTER
    }
}

export const newsLetterSubscription = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'dashboard/news-letter-subscription', {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: CREATE_NEWS_LETTER,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getCustomerServiceData = () => (dispatch) => {
    dispatch(emptyError());
    fetch(ENV.url + "cms/customer-service-schedule", {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            "content-type": "application/json",
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.status) {

                dispatch({
                    type: GET_CUSTOMER_SERVICE_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getuserInfoData = (qs = '') => (dispatch) => {
    ENV.getUserKeys();
    dispatch(emptyError());
    fetch(ENV.url + "cms/user-info?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            "content-type": "application/json",
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: GET_USER_INFO_LIST,
                    payload: data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};


// User Detail 
export const getuserDetail = (qs = '') => (dispatch) => {
    ENV.getUserKeys();
    dispatch(emptyError());
    fetch(ENV.url + "user/user-detail?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            "content-type": "application/json",
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.status) {
                ENV.encryptUserData(data.data);
                dispatch({
                    type: GET_USER_DETAIL,
                    payload: data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};