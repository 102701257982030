import { BEFORE_PROMO, GET_PROMO_LIST, EMPTY_PROMO_LIST } from '../../redux/types'
import { ENV } from './../../config/config'

const initialState = {
    promoList: {},
    getPromoListAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case EMPTY_PROMO_LIST:
            return {
                ...state,
                promoList: []
            }
        case GET_PROMO_LIST:
            return {
                ...state,
                promoList: ENV.mergeDocs(state.promoList, action.payload),
                getPromoListAuth: true
            }
        case BEFORE_PROMO:
            return {
                promoList: [],
                getPromoListAuth: false
            }
        default:
            return {
                ...state
            }
    }
}