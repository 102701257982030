import { BEFORE_LOI, GET_LOI_LIST } from '../../redux/types'

const initialState = {
    links: [],
    getLinksAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LOI_LIST:
            return {
                ...state,
                links: action.payload,
                getLinksAuth: true
            }
        case BEFORE_LOI:
            return {
                links: [],
                getLinksAuth: false
            }
        default:
            return {
                ...state
            }
    }
}