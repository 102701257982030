import { render } from "@testing-library/react";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./home.css"
function BlockConfirmation(props) {
    return (
        <div>


            <Modal id="unblocked" className="unblock"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4" >
                    {/* <h4>Centered Modal</h4> */}
                   
                    Are you sure you want to unblock <b>Stephen</b>?
                  
                </Modal.Body>
                <Modal.Footer className="mb-4">
                    <Button className=" btn btn-blue-grad btn btn-primary" onClick={props.onHide}>Yes</Button>
                    <Button className=" btn btn-blue-outline btn btn-primary" onClick={props.onHide}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default BlockConfirmation


