import { EMPTY_ERRORS, BEFORE_SHARED, SET_USER_DATA, SET_ACTIVE_USER_DATA, SET_COUNTRY_LANGUAGE_DATA, ADD_COUNTRY_REQUEST, SUBMIT_CONTACT_SUPPORT_REQUEST, GET_ERRORS, COUNTRY_CODE_DATA, SET_USER_LOCATION, SITE_DATA,SETTINGS_DATA } from '../types';
import { ENV } from './../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}
export const beforeShared = () => {
    return {
        type: BEFORE_SHARED
    }
}
export const setUserLocation = (data) => {
    data.fromLocation = true
    return {
        type: SET_USER_LOCATION,
        payload: data
    }
}
export const setUserData = (data) => {
    return {
        type: SET_USER_DATA,
        payload: data
    }
}
export const setActiveUserData = (data) => {
    return {
        type: SET_ACTIVE_USER_DATA,
        payload: data
    }
}

export const checkUserCountryLang = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'country/info', {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_COUNTRY_LANGUAGE_DATA,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const submitCountryRequest = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'country/add-request', {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_COUNTRY_REQUEST,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const submitContactSupport = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'auth/contact-support', {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SUBMIT_CONTACT_SUPPORT_REQUEST,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getCountryCode = (payload) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'phone/get-country-code', {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: COUNTRY_CODE_DATA,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const loadSiteData = (qs = '') => async (dispatch) => {
    dispatch(emptyError());
    try {
        const location = await ENV.getLatLng();

        if (location.status === "OK") {
            const { lat, lng } = location;
            qs += `&lat=${lat}&lng=${lng}`;
        }
        const response = await fetch(`${ENV.url}cms/site-data?${qs}`, {
            method: "GET",
            headers: {
                lang: ENV.getUserLang(),
                "content-type": "application/json",
                Authorization: ENV.Authorization,
                "x-auth-token": ENV.x_auth_token,
                'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : '',
                credentials: 'include',
                cookies: document.cookie
            },
        });

        const data = await response.json();

        if (data.status) {
            dispatch({
                type: SITE_DATA,
                payload: data,
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ERRORS,
            payload: error,
        });
    }
};


export const loadSettingsData = (qs = '') => (dispatch) => {
    dispatch(emptyError());
    fetch(ENV.url + "language/settingsData?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            "content-type": "application/json",
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    })
        .then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: SETTINGS_DATA,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};