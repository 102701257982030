import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { beforeFooter, newsLetterSubscription, getuserInfoData } from './main-footer.action';
import { beforeFooterContent, getFooterContent } from '../../components/content/content.action'
import './main-footer.css';
import { predefinedContent } from './content'
import { ENV } from './../../config/config';
import SmallLoader from './../Shared/SmallLoader';

function Mainfooter(props) {
	let { registrationData } = ENV.getUserKeys('registrationData');
	let country = '';
	if (registrationData && registrationData.country) {
		country = registrationData.country;
	}
	const [email, setEmail] = useState('');
	const [smallLoader, setSmallLoader] = useState(false)
	const [pageContent, setContent] = useState(predefinedContent)
	const [load, setLoad] = useState(true)
	const [loadCMSpages, setCMSpages] = useState(true)

	useEffect(() => {
		if (loadCMSpages) {
			AOS.init();

			if (!props.content.footerPageContentAuth) {
				props.getFooterContent('footer')
			}

			props.beforeFooter();
			setCMSpages(false);
		}
	}, [loadCMSpages]);

	// set page content for given lang
	useEffect(() => {
		if (props.content.footerPageContentAuth) {
			const { footerPageContent } = props.content

			// set page content only if found
			if (footerPageContent.content)
				setContent(footerPageContent.content.content)
			// props.beforeFooterContent()
		}
	}, [props.content.footerPageContentAuth])

	// useEffect(() => {
	// 	if (getUserCall) {
	// 		if (_id) {
	// 			let body = { _id, country };
	// 			let qs = ENV.objectToQueryString(body);
	// 			props.getuserInfoData(qs);
	// 		}
	// 		else {
	// 			props.getuserInfoData();

	// 		}
	// 		getUserCall = false;
	// 	}
	// }, [getUserCall]);

	useEffect(() => {
		if (props.footer.newsLetterAuth && load) {
			AOS.init();
			setLoad(false);
			props.beforeFooter();
		}
	}, [props.footer.newsLetterAuth, load]);
	useEffect(() => {
		if (props.errors && Object.keys(props.errors).length) {
			setSmallLoader(false)
		}
	}, [props.errors]);
	function newsLetter() {
		setSmallLoader(true)
		let payload = {
			email
		};
		props.newsLetterSubscription(payload);
		setEmail('')
	}
	useEffect(() => {
		if (props.footer.newsLetterAuth) {
			setSmallLoader(false)
			props.beforeFooter();
		}
	}, [props.footer.newsLetterAuth]);
	// let userInfoData = props.footer.userInfoData && props.footer.userInfoData.data ? props.footer.userInfoData.data : [];
	let userInfoData = props.shared.siteDate && props.shared.siteDate.support ? props.shared.siteDate.support : {};
	let cities = props.shared.siteDate && props.shared.siteDate.cities ? props.shared.siteDate.cities : [];
	let searches = props.shared.siteDate && props.shared.siteDate.searches ? props.shared.siteDate.searches : [];
	let pages = props.shared.siteDate && props.shared.siteDate.pages ? props.shared.siteDate.pages : {};
	let exception_ip = props.shared.siteDate && props.shared.siteDate.location && props.shared.siteDate.location.exception_ip ? props.shared.siteDate.location.exception_ip : "";
	if (!country || exception_ip) {
		country = props.shared.siteDate && props.shared.siteDate.location && props.shared.siteDate.location.country ? props.shared.siteDate.location.country : "";
	}

	country = country === "Pakistan" ? pageContent.pakistanText : country === "Spain" ? pageContent.spainText : country

	return (
		<footer id="footer" className="">
			<div className="footer-top">
				<div className="container">
					<div className="heading-section mb-lg-5 mb-md-4 mb-sm-3 mb-2" data-aos="fade-down" data-aos-offset="80" data-aos-duration="600">
						<h2>{country} {pageContent.countryMainPagesText}</h2>
					</div>
					<div className="footer-tags-block mb-3 mb-md-5" data-aos="fade-down" data-aos-offset="80" data-aos-duration="600" data-aos-delay="250">
						<h3 className="mb-4">{pageContent.mainCitiesOfCountry} {country}</h3>
						<ul className="tags-cloud cities-tags-cloud list-unstyled">
							{
								cities && cities.length ?
									cities.map((city, index) => {
										return (
											city._id ?
												<li key={index}><Link to={`/items/0/${city._id}/0/0/0/0/0/0/0/0`}>{city._id}</Link></li>
												: ''
										)
									}) :
									""
							}
						</ul>
					</div>
					<div className="footer-tags-block mb-5" data-aos="fade-down" data-aos-offset="80" data-aos-duration="600" data-aos-delay="500">
						<h3 className="mb-4">{pageContent.mostSearchedCity} {country}</h3>
						<ul className="tags-cloud adds-tags-cloud list-unstyled">
							{
								searches && searches.length ?
									searches.map((search, index) => {
										return (
											search._id ?
												<li key={index}><Link to={`/items/0/0/0/0/0/0/0/0/${search._id}/0`}>{search._id}</Link></li>
												: null
										)
									})
									:
									""
							}
						</ul>
					</div>
				</div>
			</div>
			<div className="footer-bottom" data-aos="fade-up" data-aos-offset="80" data-aos-duration="750">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-sm-6">
							<div className="mb-4 mb-lg-0">
								<strong className="footer-logo d-inline-block align-top">
									<Link to="/">
										<img className="img-fluid site-basic-logo" src={require('../../assets/images/logo-site-basic.png')} alt="footer-logo" />
										<img className="img-fluid site-special-logo" src={require('../../assets/images/logo-site-special.png')} alt="site-logo" />
									</Link>
								</strong>
								<div className="footer-about-text">
									<p>{pageContent.footerText}</p>
								</div>
								<div className="footer-read-more-link">
									<Link className="read-more-link d-flex align-items-center flex-row" to="/about-us">
										<span className="read-more-link-text mr-2">{pageContent.readMoreText}</span>
										<span className="d-flex justify-content-center align-items-center icon-right-arrow">
											<FontAwesomeIcon icon={faAngleRight} />
										</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="mb-4 mb-lg-0">
								<h3>{pageContent.footerUsefulLinks}</h3>
								<ul className="footer-nav list-unstyled">

									{
										pages && pages.length > 0 ?
											pages.map((val, key) => {
												return (
													val.showInUsefulLinksFooter === true ?
														<li key={key}><Link to={`/cms/${val.slug}`}>{val.title}</Link></li> : ""
												)
											})
											:
											""
									}

								</ul>
							</div>
							<div className="mb-4 mb-lg-0">
								<h3>{pageContent.newsLetterSubscription}</h3>
								<div className="added-number">
									<input className="form-control mb-2" type="email" placeholder="John@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
									<button className="w-100 btn btn-blue-grad" disabled={smallLoader} type="button" onClick={() => newsLetter()}>
										<div className="w-100 d-flex justify-content-center align-items-center">
											<span>{pageContent.subscribeButton}</span>
											{smallLoader ?
												<SmallLoader />
												: ''
											}
										</div>
									</button>
								</div>
							</div>
						</div>
						{/* <div className="col-lg-3 col-sm-6">
							<div className="mb-4 mb-lg-0">
								<h3>News Letter Subscription</h3>
								<div className="d-block d-sm-flex added-number">
									<input className="form-control" type="email" placeholder="John@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
									<button className="btn btn-primary" type="button" onClick={() => newsLetter()}>Subscribe</button>
								</div>
							</div>
						</div> */}
						<div className="col-lg-3 col-sm-6">
							<div className="mb-4 mb-lg-0">
								<h3>{pageContent.footerQuickAccess}</h3>
								<ul className="footer-nav list-unstyled">
									{
										pages && pages.length > 0 ?
											pages.map((val, key) => {
												return (
													val.showInQuickAccessFooter === true ?
														<li key={key}><Link to={`/cms/${val.slug}`}>{val.title}</Link></li> : ""
												)
											})
											:
											""
									}
								</ul>

							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="mb-4 mb-lg-0">
								<h3>{pageContent.customerServiceScheduleText}</h3>
								<div className="time-schedule-holder mb-4">
									<div className="current-status d-flex flex-row">
										<strong className="mr-1">{pageContent.customerServiceScheduleHours}:</strong>
										<ul className="footer-nav list-unstyled">
											<li>{userInfoData.telephoneAttentionHours}</li>
										</ul>
										<ul>

										</ul>
									</div>
								</div>
								<ul className="footer-contact-nav list-unstyled">
									{userInfoData.userPhone ?
										<li className="d-flex align-items-center flex-row">
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xml="preserve">
												<g id="Page-1">
													<g id="_x30_03---Call" transform="translate(-1)">
														<path id="Shape" d="M213.012,299.988c-48.87-48.87-59.904-97.739-62.394-117.319    c-0.695-5.414,1.167-10.843,5.041-14.689l39.548-39.53c5.817-5.814,6.85-14.875,2.489-21.848L134.729,8.828    C129.905,1.105,120-1.681,111.857,2.392L10.772,50C4.188,53.242,0.314,60.238,1.062,67.54    c5.296,50.317,27.233,174.009,148.789,295.573c121.556,121.565,245.23,143.493,295.574,148.79    c7.302,0.747,14.298-3.126,17.54-9.711l47.607-101.085c4.059-8.125,1.29-18.004-6.399-22.836l-97.774-62.95    c-6.97-4.364-16.03-3.34-21.849,2.472l-39.53,39.548c-3.846,3.873-9.275,5.735-14.688,5.04    C310.751,359.893,261.882,348.857,213.012,299.988z" />
														<path id="Shape_1_" d="M407.069,273.655c-9.751,0-17.655-7.905-17.655-17.655    C389.331,182.904,330.096,123.669,257,123.586c-9.75,0-17.655-7.904-17.655-17.655c0-9.751,7.905-17.655,17.655-17.655    C349.589,88.378,424.622,163.411,424.725,256C424.725,265.75,416.819,273.655,407.069,273.655z" />
														<path id="Shape_2_" d="M495.345,273.655c-9.751,0-17.655-7.905-17.655-17.655    C477.554,134.173,378.827,35.446,257,35.311c-9.75,0-17.655-7.905-17.655-17.655C239.345,7.904,247.25,0,257,0    c141.32,0.156,255.845,114.68,256,256c0,4.683-1.86,9.173-5.171,12.484C504.518,271.795,500.027,273.655,495.345,273.655z" />
													</g>
												</g>
											</svg>
											<span>
												{userInfoData.showPhoneCode ?
													userInfoData.userCode : ""}
												{userInfoData.userPhone}
											</span>
										</li>
										: ""
									}
									<li className="d-flex align-items-center flex-row">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path className="cls-1" d="M499.9,3679.1a7.186,7.186,0,0,0-11.374,8.55L487,3692l4.346-1.52A7.188,7.188,0,0,0,499.9,3679.1Zm-1.528,7.75-0.83.83c-0.687.69-2.637-.14-4.355-1.86s-2.554-3.67-1.866-4.36l0.829-.83a0.443,0.443,0,0,1,.622,0l1.037,1.04a0.437,0.437,0,0,1,0,.62l-0.622.62a6.121,6.121,0,0,0,2.9,2.91l0.623-.62a0.426,0.426,0,0,1,.622,0l1.037,1.03A0.437,0.437,0,0,1,498.367,3686.85Z" transform="translate(-487 -3677)"></path></svg>
										{
											<span>{pageContent.whatsappSupport} {<span>{userInfoData.UserWhatsappSupport ? pageContent.whatsappYesText : pageContent.whatsappNoText}</span>} </span>
										}

									</li>
									<li className="d-flex align-items-center flex-row">
										<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 500 500" enableBackground="new 0 0 500 500" xml="preserve">
											<g>
												<path d="M499.631,107.714c0-0.571,0.393-1.127,0.367-1.692L347.415,253.043l152.4,142.326   c0.089-1.018-0.185-2.043-0.185-3.081V107.714L499.631,107.714z" />
												<path d="M324.123,275.668l-62.288,59.93c-3.152,3.032-7.224,4.553-11.296,4.553c-3.989,0-7.977-1.455-11.107-4.373   l-62.119-57.885L23.917,425.748c3.73,1.341,7.733,2.381,11.926,2.381h429.393c6.227,0,12.065-1.896,17.142-4.725L324.123,275.668z" />
												<path d="M250.361,301.423L483.224,77.108c-5.272-3.135-11.409-5.238-17.987-5.238H35.843   c-8.565,0-16.414,3.326-22.527,8.398L250.361,301.423z" />
												<path d="M0,113.177v279.107c0,3.204,0.736,6.293,1.536,9.245l151.493-145.883L0,113.177z" />
											</g>
										</svg>
										<Link to="/contact-support">{pageContent.footerContactUs}</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-copyright-text">
				<div className="container">
					<div className="copyright-text" data-aos="fade-right" data-aos-offset="10" data-aos-duration="600" data-aos-delay="250">
						<p>{pageContent.copyRightText} {new Date().getFullYear()} {pageContent.megaAdsReservedRights}</p>
					</div>
				</div>
				<div className="project-by" data-aos="flip-down" data-aos-offset="0" data-aos-duration="600" data-aos-delay="100">
					<a className="d-inline-block align-top" href="https://www.arhamsoft.com" target="_blank" rel="noopener noreferrer">
						<img className="img-fluid" src={require('../../assets/images/project-by.svg')} alt="project" />
					</a>
				</div>
			</div>
		</footer >
	);
}
const mapStateToProps = state => ({
	errors: state.errors,
	footer: state.footer,
	shared: state.shared,
	content: state.content

})
export default connect(mapStateToProps, { beforeFooter, newsLetterSubscription, getuserInfoData, beforeFooterContent, getFooterContent })(Mainfooter);