import React, { Suspense } from 'react'
import routes from './routes'
import { PrivateRoute } from './privateRoute'
import { Switch } from "react-router-dom"
import Languages from './components/languages/languages'
import FullPageLoader from './components/Shared/FullPageLoader';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import "aos/dist/aos.css"
import './App.css'

const App = (props) => {
	return (
		<React.Fragment>
			<Suspense fallback={<FullPageLoader />}>
				<div className="wrapper">
					<Languages />
					<Switch>
						{
							routes.map((route, index) => {
								return (
									<PrivateRoute
										key={index}
										path={route.path}
										exact={route.exact}
										access={true}
										component={props => (
											<route.layout {...props}>
												<route.component {...props} />
											</route.layout>
										)}
									/>
								)
							})
						}
					</Switch>
				</div>
			</Suspense>
		</React.Fragment>
	)
}

export default App
