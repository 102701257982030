var CryptoJS = require("crypto-js");
var dataEncryptionKey = 'mAeUgGaKaDdDaKuGEnC123';
export const ENV = {
    
    // socketConString: 'http://localhost:8080',
    // url: 'http://localhost:8080/v1/',

    // url: 'https://dev.topspot.es/v1/',
    // socketConString: 'https://dev.topspot.es',

    url: 'https://megaads.arhamsoft.com/v1/',
    socketConString: 'https://megaads.arhamsoft.com',

    // dataEncryptionKey: 'mAeUgGaKaDdDaKuGEnC123',

    // Headers
    captcha_site_key:'6LcdDXcaAAAAAPDlbdgZP-vZnzsBxVLlanFftP5y',
    Authorization: 'Bearer U2FsdGVkX19N17nCvW63G3X1nBf6ixXpyzfSyArEw/YCEAbEYSLiaFaQN4p9Sbr3BH8fhcDBZQvhccdKLpCFcB1BfDnzVKe3WfX6bgWRiik=',
    x_auth_token: 'F8KLhlTVO8psuIB',

    //set user in local storage
    encryptUserData: function (data) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && originalData.callingCode) {
                data.callingCode = originalData.callingCode;
            }
            if (originalData && originalData.accessToken && !data.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify(data);
        var encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
        localStorage.setItem('encuse', encryptedUser);
        return true;
    },

    //set user in local storage
    updateEncryptUserData: function (data) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && Object.keys(originalData).length) {
                originalData = { ...originalData, ...data };
            }
            originalData = JSON.stringify(originalData);
            var encryptedUser = CryptoJS.AES.encrypt(originalData, dataEncryptionKey).toString();
            localStorage.setItem('encuse', encryptedUser);
        }
        return true;
    },

    //get active user type from local storage
    getActiveUserType: function () {
        let userTypeData = localStorage.getItem('aut');
        if (userTypeData) {
            var bytes = CryptoJS.AES.decrypt(userTypeData, dataEncryptionKey);
            var userType = bytes.toString(CryptoJS.enc.Utf8);
            return parseInt(userType);
        }
        return 0;
    },

    //decode passed data
    decodePassedData: function (data) {
        var bytes = CryptoJS.AES.decrypt(data, dataEncryptionKey);
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    },

    //return required keys
    getUserKeys: function (keys = null) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            let user = {};
            if (keys) {
                keys = keys.split(" ");
                for (let key in keys) {
                    let keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            }
            else {
                user = originalData;
            }
            return user;
        } else {
            return {};
        }

    },

    getUserLang: function () {
        const lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        return lang
    },

    //clear everything from localstorage
    clearStorage: function () {
        localStorage.removeItem('CanLogin');
        localStorage.removeItem('encuse');
        localStorage.removeItem('promoId');
        localStorage.removeItem('email');
        localStorage.removeItem('passwordMatched');

    },

    objectToQueryString: function (body) {
        const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
        return qs;
    },

    integerNumberValidator: function (e) {
        // console.log('e.ctrlKey', e.ctrlKey)
        // console.log('e.keyCode', e.keyCode)

        // Allow: backspace, delete, tab, escape, enter and .
        const specialKeys = [46, 8, 9, 27, 13, 110]

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    },

    decimalNumberValidator: function (evt) {
        let e = evt || window.event;
        let key = e.keyCode || e.which;
        if ((!e.shiftKey && !e.altKey && !e.ctrlKey) &&
            (((key === 190 || key === 110) ||
                (key >= 48 && key <= 57)) ||
                (key >= 96 && key <= 105) ||
                key === 8 || key === 9 || key === 13 ||
                key === 35 || key === 36 ||
                key === 37 || key === 39 ||
                key === 46 || key === 45)) {
        }
        else {
            e.returnValue = false;
            if (e.preventDefault) e.preventDefault();
        }
    },

    mergeDocs: function (oldDocs, newDocs) {
        let data = {
            limit: newDocs.limit,
            page: newDocs.page,
            total: newDocs.total,
            pages: newDocs.pages
        }
        let mergedDocs = []
        if (oldDocs && oldDocs.docs) {
            mergedDocs = oldDocs.docs
            mergedDocs.push(...newDocs.docs)
        }
        else {
            mergedDocs = newDocs.docs
        }
        data.docs = mergedDocs
        return data
    },
    getLatLng: async function () {
        if (!navigator.geolocation) {
          console.error("Geolocation is not supported by this browser.");
          return { status: "Fail" };
        }
      
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
      
          const { latitude: lat, longitude: lng } = position.coords;
          return { lat, lng, status: "OK" };
        } catch (error) {
          console.error("Error getting geolocation data:", error);
          return { status: "Fail" };
        }
    },
  

    // strong password regex
    strongPassword: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
    strongPasswordMsg: 'Password must contain Upper Case, Lower Case , a Special Character , a Number and must be at least 8 characters in length.',

    // strong password regex for student
    stdStrongPassword: new RegExp('^.{8,}$'),
    stdStrongPasswordMsg: 'Password must be of at least 8 characters.',

    // cdn base url
    cdnBaseUrl: 'https://d206oo7zkzq77l.cloudfront.net',
}
