import { combineReducers } from 'redux'
import sharedReducer from './Shared/shared.reducer'
import landingReducer from './../components/landing/landing.reducer'
import profileReducer from './../components/edit-profile/profile.reducer'
import adReducer from './../components/post-ad-details/ads.reducer'
import dashboardReducer from './../components/dashboard/dashboard.reducer'
import errorReducer from './Shared/error/error.reducer'
import loginReducer from './../components/sign-in/login.reducer'
import footerReducer from './../components/main-footer/main-footer.reducer'
import passwordReducer from './../components/password/password.reducer'
import phoneReducer from './../components/manage-phone-numbers/phone.reducer'
import cmsPageReducer from './../components/cms-pages/cms.page.reducer'
import faqReducer from "./../components/faqs/faq.reducer"
import billingReducer from './../components/billing/billing.reducer'
import ticketsReducer from './../components/tickets/tickets.reducer'
import bannerReducer from './../components/banner/banner.reducer'
import chatReducer from './../components/chat-manage-users/chat.reducer'
import promoReducer from './../components/promos/promos.reducer'
import languageReducer from './../components/languages/languages.reducer'
import contentReducer from './../components/content/content.reducer'
import paymentReducer from './../components/payment/payment.reducer'
import loiReducer from './../components/loi/loi.reducer'
import bonusPaymentsReducer from '../components/bonus-payments/bonus-payments.reducer'

export default combineReducers({
    shared: sharedReducer,
    landing: landingReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    ads: adReducer,
    errors: errorReducer,
    login: loginReducer,
    password: passwordReducer,
    phone: phoneReducer,
    footer: footerReducer,
    cmsPage: cmsPageReducer,
    faqs: faqReducer,
    billing: billingReducer,
    tickets: ticketsReducer,
    banner: bannerReducer,
    chat: chatReducer,
    promos: promoReducer,
    language: languageReducer,
    content: contentReducer,
    payment: paymentReducer,
    loi: loiReducer,
    bonusPayments: bonusPaymentsReducer
})