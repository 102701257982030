import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { beforeBanner, getBanners, hideNormalBanners, emptyError, updateBannerStats } from '../banner.action'
import { getBannerContent } from '../../content/content.action'
import { ENV } from '../../../config/config'
import './normal-banner.css'
import { predefinedContent } from '../content'
import defaultBannerImage from '../../../assets/images/promo-banner.jpg'

function NormalBanner(props) {
    const { _id } = ENV.getUserKeys('_id')
    const [banners, setBanners] = useState([])
    let [showBannerIndex, setBannerDisplayIndex] = useState(0)
    const [pageContent, setContent] = useState(predefinedContent)
    const [componentInitialCall, setInitialCall] = useState(true)


    useEffect(() => {
        if (componentInitialCall) {
            const payload = { bannerType: 1 }
            if (_id) {
                payload.userId = _id
            }
            const qs = ENV.objectToQueryString(payload)
            props.getBanners(qs, payload.bannerType)
            if (!props.content.promotionalBannerContentAuth) {
                props.getBannerContent('banner')
            }

            setInitialCall(false)
        }
    }, [componentInitialCall])

    // set page content for given lang
    useEffect(() => {
        if (props.content.promotionalBannerContentAuth) {
            const { promotionalBannerContent } = props.content

            // set page content only if found
            if (promotionalBannerContent.content)
                setContent(promotionalBannerContent.content.content)

        }
    }, [props.content.promotionalBannerContentAuth])

    useEffect(() => {
        if (props.banner.getNormalBannersAuth) {
            let banners = props.banner.normalBanners
            setBanners(banners)
            let bannerId = banners && banners[0] ? banners[0]._id : ""
            let payloadStats = { bannerId }
            if (bannerId) {
                props.updateBannerStats(payloadStats)
                props.beforeBanner()
            }
        }
    }, [props.banner.getNormalBannersAuth])

    const handleClose = (index) => {
        banners[index] = { ...banners[index], hide: true }
        setBanners(banners)
        setBannerDisplayIndex(++showBannerIndex)

        if (showBannerIndex === banners.length) {
            props.hideNormalBanners()
        }
        let bannerId = banners && banners[0] ? banners[0]._id : ""
        let payloadStats = { bannerId, pressed: true }
        props.updateBannerStats(payloadStats)
        props.beforeBanner()
    }

    return (
        <>{
            !props.banner.hideNormalBanners &&
            <div id="normal-banner-container" className="row">
                {
                    banners && banners.map((banner, index) => {
                        // $(`#normal-banner-${index}`).css('background', `url('${banner.backgroundImage}')`)

                        // if (banner.backgroundImage)
                        //     $(`#normal-banner-${index}`).css('background', `url('${banner.backgroundImage}')`)
                        // else
                        //     $(`#normal-banner-${index}`).css('background', `url( '../../../assets/images/bg-01.jpg')`)

                        return (
                            <div className="col-12" key={index}>
                                <Modal
                                    show={!banner.hide && index === showBannerIndex}
                                    onHide={() => handleClose(index)}
                                    className="normal-code-modal"
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton />
                                    <Modal.Body id={`normal-banner-${index}`} className="norm-banner"
                                        style={{ background: `url(${banner.backgroundImage ? banner.backgroundImage : defaultBannerImage})` }}
                                    >
                                        <div className="d-flex w-100 h-100 py-5 align-items-center">
                                            <div className="py-5 py-md-3 w-100 d-flex flex-column flex-lg-row align-items-center justify-content-center text-center">
                                                <div className="w-100 w-lg-50 mb-4 mb-lg-0 normal-text-block d-flex flex-column">
                                                    <span className="d-block normal-text">
                                                        {pageContent.normalBannerHeading}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {banner.bannerContent &&
                                            <>
                                                <div className="normal-des">
                                                    <p dangerouslySetInnerHTML={{ __html: banner.bannerContent }} />
                                                </div>
                                                <div className="text-center">
                                                    <span className="btn btn-blue-outline" onClick={() => handleClose(index)}>OK</span>
                                                </div>
                                            </>
                                        }
                                    </Modal.Body>
                                </Modal>
                            </div>
                        )
                    })
                }
            </div>
        }</>
    )
}

const mapStateToProps = state => ({
    errors: state.errors,
    banner: state.banner,
    content: state.content
})
export default connect(mapStateToProps, { beforeBanner, getBanners, hideNormalBanners, emptyError, getBannerContent, updateBannerStats })(NormalBanner)