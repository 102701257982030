export const predefinedContent = {
    stayText: "STAY",
    stayAndWin: "MINUTE(S) ON THIS BANNER AND WIN",
    freeTickets: "FREE TICKETS",
    getText: "GET",
    timesFreeTickets: "FREE TICKETS TIME",
    win: "WIN",
    tickets: "TICKET(S)",
    get: "GET",
    extraTicketOnPurchase: "EXTRA TICKETS ON PURCHASE OVER",
    euros: "EUROS",
    promoCode: "Your Promo Code",
    minutes: "Minutes",
    promoDetailsHeading: "Promo Details",
    urlHeading: "URL",
    urlNotAvailable: "N/A",
    validFromHeading: "Valid From",
    expiryDateHeading: "Expiry Date",
    promotionType: "Promotion Type",
    otherDetailsText: "More Details",
    textAreaPromotionNotAvailable: "N/A",
    textAreaPromotionEndedNotAvailable: "N/A",
    descriptionHeading: "Description",
    acceptButton: "Accept",
    normalBannerHeading: "NORMAL BANNER",
    spendTimeText: "Perfect! You've spent time on this banner"
}