import React from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../../../assets/images/avatar.png"
import Avatar2 from "../../../assets/images/avatar2.png"
import Avatar3 from "../../../assets/images/avatar3.png"
import Avatar4 from "../../../assets/images/avatar4.png"
import Avatar5 from "../../../assets/images/avatar5.png"
import Avatar6 from "../../../assets/images/avatar6.png"
import { Button, Col, Container } from "react-bootstrap";
import "./home.css"
import BlockConfirmation from "./blockConfirmation";
const BlockUser = () => {
    const history = useHistory();
    const [modalShow, setModalShow] = React.useState(false);


    return (
        <div className="blocking-list">
            <Container>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center phone-manage justify-content-between phone-manage-note flex-wrap">
                            <h2 className="d-block">Blocked Users: 5</h2>
                            <Button className="btn btn-blue-grad btn btn-primary" onClick={()=>history.push("/chat/messages-list")}>List Ads with Chat</Button>
                        </div>
                        <p>List of users that you have blocked. Click on Unblock, if you want to unblock any user.</p>
                    </div>
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table megaad-site-table phones-list-table">
                                <thead>
                                    <tr>
                                        <th> #</th>
                                        <th>Thumbnail</th>
                                        <th>User & Date</th>
                                        <th>Reason & Location</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="avatar-img">
                                                <img className="img-fluid" src={Avatar} alt="" />
                                            </div>

                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                Name OR User ID
                                            </span>
                                            <span className="d-block">
                                                2022/6/12
                                            </span>

                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                <strong>Reason:</strong> Disrespectful language
                                            </span>
                                            <span className="d-block">
                                                <strong>City:</strong> Lahore
                                            </span>

                                        </td>
                                        <td>
                                            <div>
                                                <Button className='btn btn-blue-grad btn btn-primary' variant="primary" onClick={() => setModalShow(true)}>
                                                    Unblock
                                                </Button>
                                         </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="avatar-img">
                                                <img className="img-fluid" src={Avatar2} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                Name OR User ID
                                            </span>
                                            <span className="d-block">
                                                2022/6/12
                                            </span>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                <strong>Reason:</strong> Disrespectful language
                                            </span>
                                            <span className="d-block">
                                                <strong>City:</strong> Lahore
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <Button className='btn btn-blue-grad btn btn-primary' variant="primary" onClick={() => setModalShow(true)}>
                                                    Unblock
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="avatar-img">
                                                <img className="img-fluid" src={Avatar3} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                Name OR User ID
                                            </span>
                                            <span className="d-block">
                                                2022/6/12
                                            </span>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                <strong>Reason:</strong> Disrespectful language
                                            </span>
                                            <span className="d-block">
                                                <strong>City:</strong> Lahore
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <Button className='btn btn-blue-grad btn btn-primary' variant="primary" onClick={() => setModalShow(true)}>
                                                    Unblock
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="avatar-img">
                                                <img className="img-fluid" src={Avatar4} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                Name OR User ID
                                            </span>
                                            <span className="d-block">
                                                2022/6/12
                                            </span>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                <strong>Reason:</strong> Disrespectful language
                                            </span>
                                            <span className="d-block">
                                                <strong>City:</strong> Lahore
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <Button className='btn btn-blue-grad btn btn-primary' variant="primary" onClick={() => setModalShow(true)}>
                                                    Unblock
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="avatar-img">
                                                <img className="img-fluid" src={Avatar5} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                Name OR User ID
                                            </span>
                                            <span className="d-block">
                                                2022/6/12
                                            </span>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                <strong> Reason:</strong> Disrespectful language
                                            </span>
                                            <span className="d-block">
                                                <strong>City:</strong> Lahore
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <Button className='btn btn-blue-grad btn btn-primary' variant="primary" onClick={() => setModalShow(true)}>
                                                    Unblock
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="avatar-img">
                                                <img className="img-fluid" src={Avatar6} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                Name OR User ID
                                            </span>
                                            <span className="d-block">
                                                2022/6/12
                                            </span>
                                        </td>
                                        <td>
                                            <span className="d-block mb-2">
                                                <strong>Reason:</strong> Disrespectful language
                                            </span>
                                            <span className="d-block">
                                                <strong>City:</strong> Lahore
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <Button className='btn btn-blue-grad btn btn-primary' variant="primary" onClick={() => setModalShow(true)}>
                                                    Unblock
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="bottom-btn-section text-center pt-0 pt-sm-3 pt-md-5">
                            <button className="btn-blue-grad d-inline-block align-top" type="button" disabled={(false)}>
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                    <span>Load More</span>
                                    {/* {smallLoader ?
                                        <SmallLoader />
                                        : ''
                                    } */}
                                </div>  
                            </button>
                        </div>
                        <BlockConfirmation
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                </div>

            </Container>


        </div>
    )
}
export default BlockUser