import React, { Component } from "react";
import { connect } from 'react-redux';
import { checkUserCountryLang } from './../redux/Shared/shared.action';

class Unauth extends Component {
  // componentDidMount() {
  //   if(!this.props.shared.activeUserCountryLangAuth) {
  //     this.props.checkUserCountryLang();
  //   }
  // }
  componentDidUpdate() {
    if(this.props.errors.invalidCountry && this.props.location.pathname.indexOf("/validate-country")  < 0) {
      this.props.history.push('/validate-country');
    }
    if(this.props.errors.invalidCountryLogin && this.props.location.pathname.indexOf("/validate-country")  < 0) {
      this.props.history.push('/validate-country/1');
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}

// export default Unauth;
const mapStateToProps = state => ({
  errors: state.errors,
  shared: state.shared
});
export default connect(mapStateToProps, { checkUserCountryLang })(Unauth);
