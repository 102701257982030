import { BEFORE_BILLING, GET_BILLING, GET_BILLING_LIST, EMPTY_BILLING_LIST } from '../../redux/types'
import { ENV } from './../../config/config'

const initialState = {
    bill: {},
    getBillAuth: false,
    billingList: {},
    getBillingListAuth: false,
    getDownloadBillAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BILLING:
            return {
                ...state,
                bill: action.payload,
                getBillAuth: true
            }
        case EMPTY_BILLING_LIST:
            return {
                ...state,
                billingList: []
            }
        case GET_BILLING_LIST:
            return {
                ...state,
                billingList: ENV.mergeDocs(state.billingList, action.payload),
                getBillingListAuth: true
            }
        case BEFORE_BILLING:
            return {
                bill: {},
                getBillAuth: false,
                billingList: [],
                getBillingListAuth: false,
                getDownloadBillAuth: false
            }
        default:
            return {
                ...state
            }
    }
}