import {
    BEFORE_CHAT,
    GET_USER,
    GET_EXTERNAL_ID,
    GET_PUBLISHER_ID
} from '../../redux/types';

const initialState = {
    chatList: {},
    getChatList: false,
    getExternalIdAuth: false,
    getPublisherIdAuth: false

}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                chatList: action.payload,
                getChatList: true
            }
        case GET_EXTERNAL_ID:
            return {
                ...state,
                externalIdList: action.payload,
                getExternalIdAuth: true
            }
        case GET_PUBLISHER_ID:
            return {
                ...state,
                publisherIdList: action.payload,
                getPublisherIdAuth: true
            }
        case BEFORE_CHAT:
            return {
                ...state,
                getChatList: false,
                getExternalIdAuth: false,
                getPublisherIdAuth: false


            }

        default:
            return {
                ...state
            }
    }
}
