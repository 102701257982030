import { lazy } from 'react';
import AddMessages from './components/messages/home/addMessages';
import BlockUser from './components/messages/home/blockUser';
import MessageList from './components/messages/home/messageList';

//importing layouts....
import Auth from './layouts/Auth';
import UnAuth from './layouts/UnAuth';

//importing views for UnAuth layout....
const Signin = lazy(() => import('./components/sign-in/sign-in'))
const Setpassword = lazy(() => import('./components/password/set-password'))
const ValidateCountry = lazy(() => import('./components/validate-country/validate-country'))
const ForgotPassword = lazy(() => import('./components/password/forgot-password'))
const ReSetPassword = lazy(() => import('./components/password/reset-password'))
const ForceReSetPassword = lazy(() => import('./components/password/force-reset-password'))
const ContactSupport = lazy(() => import('./components/contact-support/contact-support'))
const ThankYouSignUp = lazy(() => import('./components/thankyou-signup/thankyou-signup'))
const VerifyCountry = lazy(() => import('./components/verify-country/verify-country'))
const PinVerification = lazy(() => import('./components/pin-verification/pin-verification'))
const PhoneVerification = lazy(() => import('./components/phone-verification/phone-verification'))
const UpdateEmail = lazy(() => import('./components/edit-profile/update-email'))
const Maintenance = lazy(() => import('./components/maintenance/maintenance'))
const AccessSite = lazy(() => import('./components/accessSite/access-site'))
const NotFound = lazy(() => import('./components/maintenance/notFound'))
const SMSServiceOff = lazy(() => import('./components/sms-service-off/sms-service-off'))

//importing views for Auth layout....
const Landing = lazy(() => import('./components/landing/landing'))
const Dashboard = lazy(() => import('./components/dashboard/dashboard'))
const AdDetail = lazy(() => import('./components/ad-detail/ad-detail'))
// import MyAdDetail from './components/my-ad-detail/my-ad-detail';
// import SearchresultsGrid from './components/serach-results-grid/search-results-grid';
// import SearchresultsMap from './components/serach-results-map/search-results-map';
const Editprofile = lazy(() => import('./components/edit-profile/edit-profile'))
const Profilesettings = lazy(() => import('./components/profile-settings/profile-settings'))
const PasswordManagement = lazy(() => import('./components/password-management/password-management'))
const TrustCertification = lazy(() => import('./components/trust-certification/trust-certification'))
const MyFavoriteAds = lazy(() => import('./components/my-favorite-ads/my-favorite-ads'))
const GeneratedBill = lazy(() => import('./components/generated-bill/generated-bill'))
const AdPlanner = lazy(() => import('./components/ad-planner/ad-planner'))
const RenewAd = lazy(() => import('./components/renew-ad/renew-ad'))
const RelocateAD = lazy(() => import('./components/relocate-ad/relocate-ad'))

// import Postad from './components/post-ad/post-ad';
const Editaddetails = lazy(() => import('./components/post-ad-details/edit-ad-details'))
const AllCategories = lazy(() => import('./components/all-categories/all-categories'))
const FilteredAds = lazy(() => import('./components/filtered-ads/filtered-ads'))
const Companyads = lazy(() => import('./components/company-ads/company-ads'))
const MyAds = lazy(() => import('./components/my-ads/my-ads'))
const PublicationAds = lazy(() => import('./components/publication-ads/publication-ads'))
const ManagePhoneNumbers = lazy(() => import('./components/manage-phone-numbers/manage-phone-numbers'))
const PreviewAd = lazy(() => import('./components/preview-ad/preview-ad'))
const CertificationAds = lazy(() => import('./components/certification-ads/certification-ads'))
const BuyTickets = lazy(() => import('./components/buy-tickets/buy-tickets'))
const HowItWorks = lazy(() => import('./components/how-it-works/how-it-works'))
const BrowseTasks = lazy(() => import('./components/browse-tasks/browse-tasks'))
const FAQS = lazy(() => import('./components/faqs/faqs'))
const TermsOfServices = lazy(() => import('./components/terms-of-services/terms-of-services'))
const PrivacyPolicy = lazy(() => import('./components/privacy-policy/privacy-policy'))
const AboutUs = lazy(() => import('./components/about-us/about-us'))
const Billing = lazy(() => import('./components/billing/billing'))
const Promos = lazy(() => import('./components/promos/promos'))
const PromotionDetail = lazy(() => import('./components/banner/promo-details/promotion-details'))
const PromotionDetailPage = lazy(() => import('./components/banner/promo-details/promotion-details-page'))
const CertificateAd = lazy(() => import('./components/certificate-ad/certificate-ad'))
const ReubicateAd = lazy(() => import('./components/reubicate-ad/reubicate'))
const PublicateAd = lazy(() => import('./components/publicate-ad/publicate-ad'))
const ReplanAd = lazy(() => import('./components/replan-ad/replan-ad'))
const ChatManageUsers = lazy(() => import('./components/chat-manage-users/chat-manage-users'))
const CmsPage = lazy(() => import('./components/cms-pages/cms-page'))
const PaymentSuccess = lazy(() => import('./components/payment/payment-success'))
const PaymentError = lazy(() => import('./components/payment/payment-error'))
const ChatHome = lazy(() => import('./components/messages/home/home'))
const Chat = lazy(() => import('./components/messages/chat/chat'))
const Cookies = lazy(() => import('./components/cookies/cookie'))
const SiteLocation = lazy(() => import('./components/cookies/sitelocation'))

// ____________________________________________________________________________________________________

// import Signin from './components/sign-in/sign-in'
// import Setpassword from './components/password/set-password'
// import ValidateCountry from './components/validate-country/validate-country'
// import ForgotPassword from './components/password/forgot-password'
// import ReSetPassword from './components/password/reset-password'
// import ForceReSetPassword from './components/password/force-reset-password'
// import ContactSupport from './components/contact-support/contact-support'
// import ThankYouSignUp from './components/thankyou-signup/thankyou-signup'
// import VerifyCountry from './components/verify-country/verify-country'
// import PinVerification from './components/pin-verification/pin-verification'
// import PhoneVerification from './components/phone-verification/phone-verification'
// import UpdateEmail from './components/edit-profile/update-email'
// import Maintenance from './components/maintenance/maintenance'
// import NotFound from './components/maintenance/notFound'
// import SMSServiceOff from './components/sms-service-off/sms-service-off'

// //importing views for Auth layout....
// import Landing from './components/landing/landing'
// import Dashboard from './components/dashboard/dashboard'
// import AdDetail from './components/ad-detail/ad-detail'
// // import MyAdDetail from './components/my-ad-detail/my-ad-detail';
// // import SearchresultsGrid from './components/serach-results-grid/search-results-grid';
// // import SearchresultsMap from './components/serach-results-map/search-results-map';
// import Editprofile from './components/edit-profile/edit-profile'
// import Profilesettings from './components/profile-settings/profile-settings'
// import PasswordManagement from './components/password-management/password-management'
// import TrustCertification from './components/trust-certification/trust-certification'
// import MyFavoriteAds from './components/my-favorite-ads/my-favorite-ads'
// import GeneratedBill from './components/generated-bill/generated-bill'
// import AdPlanner from './components/ad-planner/ad-planner'
// import RenewAd from './components/renew-ad/renew-ad'
// import RelocateAD from './components/relocate-ad/relocate-ad'

// // import Postad from './components/post-ad/post-ad';
// import Editaddetails from './components/post-ad-details/edit-ad-details'
// import AllCategories from './components/all-categories/all-categories'
// import FilteredAds from './components/filtered-ads/filtered-ads'
// import Companyads from './components/company-ads/company-ads'
// import MyAds from './components/my-ads/my-ads'
// import PublicationAds from './components/publication-ads/publication-ads'
// import ManagePhoneNumbers from './components/manage-phone-numbers/manage-phone-numbers'
// import PreviewAd from './components/preview-ad/preview-ad'
// import CertificationAds from './components/certification-ads/certification-ads'
// import BuyTickets from './components/buy-tickets/buy-tickets'
// import HowItWorks from './components/how-it-works/how-it-works'
// import BrowseTasks from './components/browse-tasks/browse-tasks'
// import FAQS from './components/faqs/faqs'
// import TermsOfServices from './components/terms-of-services/terms-of-services'
// import PrivacyPolicy from './components/privacy-policy/privacy-policy'
// import AboutUs from './components/about-us/about-us'
// import Billing from './components/billing/billing'
// import Promos from './components/promos/promos'
// import PromotionDetail from './components/banner/promo-details/promotion-details'
// import PromotionDetailPage from './components/banner/promo-details/promotion-details-page'
// import CertificateAd from './components/certificate-ad/certificate-ad'
// import ReubicateAd from './components/reubicate-ad/reubicate'
// import PublicateAd from './components/publicate-ad/publicate-ad'
// import ReplanAd from './components/replan-ad/replan-ad'
// import ChatManageUsers from './components/chat-manage-users/chat-manage-users'
// import CmsPage from './components/cms-pages/cms-page'
// import PaymentSuccess from './components/payment/payment-success'
// import PaymentError from './components/payment/payment-error'
// import ChatHome from './components/messages/home/home'
// import Chat from './components/messages/chat/chat'
// import Cookies from './components/cookies/cookie'
// import SiteLocation from './components/cookies/sitelocation'

//defining routes
const routes = [
    { path: "/", access: true, exact: true, name: "Home Page", layout: Auth, component: Landing },
    { path: "/sign-in", access: true, exact: true, name: "Sign In", layout: UnAuth, component: Signin },
    { path: "/set-password/:userId/:token", access: true, exact: true, name: "Set Password", layout: UnAuth, component: Setpassword },
    { path: "/reset-password/:userId/:token", access: true, exact: true, name: "Set Password", layout: UnAuth, component: ReSetPassword },
    { path: "/reset/:userId", access: true, exact: true, name: "Set Password", layout: UnAuth, component: ForceReSetPassword },
    { path: "/validate-country/:login?", access: true, exact: true, name: "Validate Country", layout: UnAuth, component: ValidateCountry },
    { path: "/verify-country", access: true, exact: true, name: "Verify Country", layout: UnAuth, component: VerifyCountry },
    { path: "/set-password", access: true, exact: true, name: "Set Password", layout: UnAuth, component: Setpassword },
    { path: "/forgot-password", access: true, exact: true, name: "Forgot Password", layout: UnAuth, component: ForgotPassword },
    { path: "/pin-verification/:phone?/:sendSms?/:pin?/:redirect?", access: true, exact: true, name: "Pin Verification", layout: UnAuth, component: PinVerification },
    { path: "/phone-verification", access: true, exact: true, name: "Phone Verification", layout: UnAuth, component: PhoneVerification },
    { path: "/contact-support", access: true, exact: true, name: "Contact Support", layout: UnAuth, component: ContactSupport },
    { path: "/thankyou-for-signup", access: true, exact: true, name: "Thankyou Signup", layout: UnAuth, component: ThankYouSignUp },
    { path: "/invoice/:billId?", access: true, exact: true, name: "Invoice", layout: Auth, component: GeneratedBill },
    { path: "/ad-planner", access: true, exact: true, name: "Ad Planner", layout: Auth, component: AdPlanner },
    { path: "/renew-ad", access: true, exact: true, name: "Renew Ad", layout: Auth, component: RenewAd },
    { path: "/relocate-ad", access: true, exact: true, name: "Relocate AD", layout: Auth, component: RelocateAD },
    { path: "/relocate-ad/:adId", access: true, exact: true, name: "Relocate AD", layout: Auth, component: ReubicateAd },
    { path: "/dashboard", access: true, exact: true, name: "Dashboard", layout: Auth, component: Dashboard },
    { path: "/certificate-ad/:adId?", access: true, exact: true, name: "Certificate Ad", layout: Auth, component: CertificateAd },
    { path: "/publicate-ad/:adId?", access: true, exact: true, name: "Publicate Ad", layout: Auth, component: PublicateAd },
    { path: "/replan-ad/:adId?", access: true, exact: true, name: "Replan Ad", layout: Auth, component: ReplanAd },
    { path: "/ad-detail/:adId?", access: true, exact: true, name: "Ad Detail", layout: Auth, component: AdDetail },
    // { path: "/my-ad-detail", access: true, exact: true, name: "My Ad Detail", layout: Auth, component: MyAdDetail },
    // { path: "/search-results-grid", access: true, exact: true, name: "Search Result Grid", layout: Auth, component: SearchresultsGrid },
    // { path: "/search-results-map", access: true, exact: true, name: "Search Result Map", layout: Auth, component: SearchresultsMap },
    { path: "/edit-profile", access: true, exact: true, name: "Edit Profile", layout: Auth, component: Editprofile },
    { path: "/update-email/:userId?/:token/:email", access: true, exact: true, name: "Update Email", layout: UnAuth, component: UpdateEmail },
    { path: "/profile-settings", access: true, exact: true, name: "Profile Settings", layout: Auth, component: Profilesettings },
    { path: "/password-management", access: true, exact: true, name: "Password Management", layout: Auth, component: PasswordManagement },
    { path: "/trust-certification", access: true, exact: true, name: "Trust Certification", layout: Auth, component: TrustCertification },
    { path: "/my-favorite-ads", access: true, exact: true, name: "My Favorite Ads", layout: Auth, component: MyFavoriteAds },
    // { path: "/post-ad", access: true, exact: true, name: "Dashboard", layout: Auth, component: Postad },
    // { path: "/post-ad", access: true, exact: true, name: "Post Ad", layout: Auth, component: Postaddetails },
    { path: "/all-categories", access: true, exact: true, name: "Edit Ad", layout: Auth, component: AllCategories },
    { path: "/items/:cat?/:loc?/:cat18?/:price?/:class?/:type?/:shipments?/:displacements?/:search?/:pType?/:adDistance?", access: true, exact: true, name: "Edit Ad", layout: Auth, component: FilteredAds },
    { path: "/post-ad/:adId/:editAd?", access: true, exact: true, name: "Edit Ad", layout: Auth, component: Editaddetails },
    { path: "/preview-ad/:adId", access: true, exact: true, name: "Preview Ad", layout: Auth, component: PreviewAd },
    { path: "/company-ads", access: true, exact: true, name: "Company Ads", layout: Auth, component: Companyads },
    { path: "/my-ads", access: true, exact: true, name: "My Ads", layout: Auth, component: MyAds },
    { path: "/publication-ads", access: true, exact: true, name: "Publication Ads", layout: Auth, component: PublicationAds },
    { path: "/manage-phone-numbers", access: true, exact: true, name: "Manage Phone Numbers", layout: Auth, component: ManagePhoneNumbers },
    { path: "/certification-ads", access: true, exact: true, name: "Certification Ads", layout: Auth, component: CertificationAds },
    { path: "/buy-tickets/:tickets?/:planId?/:familyId?/:subFamilyId?/:banner?", access: true, exact: true, name: "Buy Tickets", layout: Auth, component: BuyTickets },
    { path: "/how-it-works", access: true, exact: true, name: "How It Works", layout: Auth, component: HowItWorks },
    { path: "/browse-tasks", access: true, exact: true, name: "Browse Tasks", layout: Auth, component: BrowseTasks },
    { path: "/inaccessible", access: true, exact: true, name: "Maintenance", layout: UnAuth, component: Maintenance },
    { path: "/restrict", access: true, exact: true, name: "AccessSite", layout: UnAuth, component: AccessSite },
    { path: "/404", access: true, exact: true, name: "NotFound", layout: UnAuth, component: NotFound },
    { path: "/sms-service-off", access: true, exact: true, name: "SMS Service Off", layout: UnAuth, component: SMSServiceOff },
    { path: "/faqs", access: true, exact: true, name: "FAQs", layout: Auth, component: FAQS },
    { path: "/terms-of-services", access: true, exact: true, name: "Terms Of Services", layout: Auth, component: TermsOfServices },
    { path: "/privacy-policy", access: true, exact: true, name: "Privacy Policy", layout: Auth, component: PrivacyPolicy },
    { path: "/cookies-policy", access: true, exact: true, name: "Cookies", layout: UnAuth, component: Cookies },
    { path: "/site-location", access: true, exact: true, name: "SiteLocation", layout: UnAuth, component: SiteLocation },
    { path: "/about-us", access: true, exact: true, name: "About Us", layout: Auth, component: AboutUs },
    { path: "/chat-manage-users/:type?/:publisher?/:user?", access: true, exact: true, name: "Chat", layout: Auth, component: ChatManageUsers },
    { path: "/billing", access: true, exact: true, name: "Billing", layout: Auth, component: Billing },
    { path: "/promos", access: true, exact: true, name: "Promos", layout: Auth, component: Promos },
    { path: "/promo-details/:promoId?", access: true, exact: true, name: "Promotion Detail", layout: Auth, component: PromotionDetail },
    { path: "/promo-details-page/:promoId?", access: true, exact: true, name: "Promotion Detail Page", layout: Auth, component: PromotionDetailPage },
    { path: "/cms/:param", exact: true, layout: Auth, component: CmsPage },
    { path: "/payment-success", access: true, exact: true, name: "Payment Success", layout: Auth, component: PaymentSuccess },
    { path: "/payment-error", access: true, exact: true, name: "Payment Error", layout: Auth, component: PaymentError },
    { path: "/conversation-list/:senderId", access: true, exact: true, name: "Chat Home", layout: Auth, component: ChatHome },
    // { path: "/conversation-list/:senderId", access: true, exact: true, name: "Chat Home", layout: Auth, component: BlockUser },
    { path: "/chat/:senderId/:receiverId", access: true, exact: true, name: "Message Corner", layout: Auth, component: Chat },
    { path: "/chat/blocked-users", access: true, exact: true, name: "Blocked Users", layout: Auth, component: BlockUser },
    { path: "/chat/messages-list", access: true, exact: true, name: "Conversations List", layout: Auth, component: MessageList },
    { path: "/chat/add-messages", access: true, exact: true, name: "Add Message", layout: Auth, component: AddMessages },
    { path: "/*", access: true, exact: true, name: "NotFound", layout: UnAuth, component: NotFound },
];

export default routes;